
@media only screen and (max-width: 767.5px) and (orientation: landscape) {

    .course-overview-section-container{
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        height: 100%;
        /* background-color: red; */
        width: 100%;
        align-items: center;
        padding-top: 8vh;
        padding-bottom: 10vh;
    }
    
    .course-overview-section-container-main-title{
        padding-bottom: 4vh;
        font-family: PRIMARY;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: rgba(11, 35, 43, 1)
    }
    
    .course-overview-section-container-video-wrap-section{
        display: flex;
        width: 80%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10vh;
    }
    
}

@media only screen and (max-width: 767.5px) and (orientation: portrait) {

    .course-overview-section-container{
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        height: 100%;
        /* background-color: red; */
        width: 100%;
        align-items: center;
        padding-top: 3vh;
        padding-bottom: 1vh;
    }
    
    .course-overview-section-container-main-title{
        padding-bottom: 2vh;
        font-family: PRIMARY;
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        color: rgba(11, 35, 43, 1)
    }
    
    .course-overview-section-container-video-wrap-section{
        display: flex;
        width: 80%;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4vh;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1023.5px) {

    .course-overview-section-container{
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        height: 100%;
        /* background-color: red; */
        width: 100%;
        align-items: center;
        padding-top: 2vh;
        padding-bottom: 1vh;
    }
    
    .course-overview-section-container-main-title{
        padding-bottom: 4vh;
        font-family: PRIMARY;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: rgba(11, 35, 43, 1)
    }
    
    .course-overview-section-container-video-wrap-section{
        display: flex;
        width: 90%;
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
}

@media only screen and (min-width: 1024px) and (max-width: 1439.5px) {

    .course-overview-section-container{
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        height: 100%;
        /* background-color: red; */
        width: 100%;
        align-items: center;
        padding-top: 8vh;
        padding-bottom: 10vh;
        position: relative; 
        overflow: visible;
    }

   
    


.course-overview-section-container::before{
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -150px; /* Adjust to overlap the previous section */
    left: 10%; /* Adjust horizontally */
    width: 400px; /* Adjust size of the SVG */
    height: 250px; /* Adjust size of the SVG *
    /* background-color: red; */
    background-image: url('../../../../new_assets/Svg/CompassSvg.svg'); /* Add your SVG path here */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -10;
    /* opacity: 0.6; */
}
    
    .course-overview-section-container-main-title{
        padding-bottom: 4vh;
        font-family: PRIMARY;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: rgba(11, 35, 43, 1)
    }
    
    .course-overview-section-container-video-wrap-section{
        display: flex;
        width: 80%;
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
}

@media only screen and (min-width: 1440px) {

    .course-overview-section-container{
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        height: 100%;
        /* background-color: red; */
        width: 100%;
        align-items: center;
        padding-top: 8vh;
        padding-bottom: 10vh;
        position: relative; 
        overflow: visible;
    }


.course-overview-section-container::before{
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -170px; /* Adjust to overlap the previous section */
    left: 10%; /* Adjust horizontally */
    width: 250px; /* Adjust size of the SVG */
    height: 500px; /* Adjust size of the SVG */
    /* background-color: red; */
    background-image: url('../../../../new_assets/Svg/CompassSvg.svg'); /* Add your SVG path here */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -1; /* Places the SVG behind other content */
}

    .course-overview-section-container-main-title{
        padding-bottom: 4vh;
        font-family: PRIMARY;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: rgba(11, 35, 43, 1)
    }
    
    .course-overview-section-container-video-wrap-section{
        display: flex;
        width: 80%;
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
}

