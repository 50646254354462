
@media only screen and (max-width: 767.5px) and (orientation: landscape) {

  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
    padding-top: 4vh;
    position: relative; /* Necessary for the ::before positioning */
    overflow: visible; /* Allows the SVG to overflow */
  }
  
  .testimonial-section-main-container::before {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -100px; /* Adjust to overlap the previous section */
    left: 100px; /* Adjust horizontally */
    width: 100px; /* Adjust size of the SVG */
    height: 100px; /* Adjust size of the SVG */
    /* background-image: url('../../../../../new_assets/Svg/CompassSvg.svg');  */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -1; /* Places the SVG behind other content */
  }
  
  .testimonial-section-main-title {
    font-family: 'PRIMARY';
    font-weight: 700;
    font-size: 28px;
    color: rgba(11, 35, 43, 1);
  }
  
  .testimonial-section-testinomial-cards-container {
    display: flex;
    flex-direction: row;
    width: 98%;
    height: 100%;
    padding-top: 4vh;
    justify-content: space-between;
    align-items: center;
  }
  
  .leftandrightarrow {
    display: none;
  }
  
  .testimonial-cards-container-vertiacl-scroll {
    width: 100%;
    overflow: hidden;
    overflow-x: auto ;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }


  
  .testimonial-individual-cards {
    display: flex;
    gap: 20px;
    transition: width 0.5s ease; /* Smooth width change */
  }
  
  .dots-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .dot.active {
    background-color: rgba(13, 95, 103, 1);
  }
  
  /* Set width for cards, you can adjust as needed */
  .testimonial-card {
    width: 30%; /* Adjust width based on screen size */
  }

     /* added by samir */
     .testimonial-section-main-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0;
    }
    
    .testimonial-section-main-title {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: center;
      color: #0d5f67;
    }
    
    /* .testimonial-section-testinomial-cards-container {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 1200px;
      overflow: hidden;
    } */
    
    /* .leftandrightarrow {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f0f0f0;
      margin: 0 10px;
      z-index: 2;
    } */
    
    .testimonial-cards-container-vertiacl-scroll {
      display: flex;
      gap: 20px;
      scroll-snap-type: x mandatory; 
      /* overflow-x: scroll; */
      padding: 10px;
      scroll-behavior: smooth; 
    }
    
    .testimonial-card {
      flex: 0 0 auto;
      /* width: 300px; */
      scroll-snap-align: center;
      /* border-radius: 10px; */
      /* background-color: #ffffff; */
      /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
      /* padding: 20px; */
      /* text-align: center; */
    }
    
    .dots-indicator {
      display: flex;
      gap: 10px;
      margin-top: 1rem;
    }
    
 
    .disabled-arrow {
      pointer-events: none; /* Prevent clicks */
      opacity: 0.5; /* Dim the arrow */
    }
    
 
}

@media only screen and (max-width: 767.5px) and (orientation: portrait) {

  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
    padding-top: 4vh;
    position: relative; /* Necessary for the ::before positioning */
    overflow: visible; /* Allows the SVG to overflow */
  }
  
  .testimonial-section-main-container::before {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -100px; /* Adjust to overlap the previous section */
    left: 100px; /* Adjust horizontally */
    width: 100px; /* Adjust size of the SVG */
    height: 100px; /* Adjust size of the SVG */
    /* background-image: url('../../../../../new_assets/Svg/CompassSvg.svg');  */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -1; /* Places the SVG behind other content */
  }
  
  .testimonial-section-main-title {
    font-family: 'PRIMARY';
    font-weight: 700;
    font-size: 22px;
    color: rgba(11, 35, 43, 1);
  }
  
  .testimonial-section-testinomial-cards-container {
    display: flex;
    flex-direction: row;
    width: 98%;
    height: 100%;
    padding-top: 3vh;
    justify-content: space-between;
    align-items: center;
  }
  
  .leftandrightarrow {
    display: none;
  }
  
  .testimonial-cards-container-vertiacl-scroll {
    width: 100%;
    overflow: hidden;
    overflow-x: auto ;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }


  
  .testimonial-individual-cards {
    display: flex;
    gap: 20px;
    transition: width 0.5s ease; /* Smooth width change */
  }
  
  .dots-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .dot.active {
    background-color: rgba(13, 95, 103, 1);
  }
  
  /* Set width for cards, you can adjust as needed */
  .testimonial-card {
    width: 30%; /* Adjust width based on screen size */
  }

      /* added by samir */
      .testimonial-section-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem 0;
      }
      
      .testimonial-section-main-title {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 1rem;
        text-align: center;
        color: #0d5f67;
      }
      
      /* .testimonial-section-testinomial-cards-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 1200px;
        overflow: hidden;
      } */
      
      /* .leftandrightarrow {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f0f0f0;
        margin: 0 10px;
        z-index: 2;
      } */
      
      .testimonial-cards-container-vertiacl-scroll {
        display: flex;
        gap: 20px;
        scroll-snap-type: x mandatory; 
        /* overflow-x: scroll; */
        padding: 10px;
        scroll-behavior: smooth; 
      }
      
      .testimonial-card {
        flex: 0 0 auto;
        /* width: 300px; */
        scroll-snap-align: center;
        /* border-radius: 10px; */
        /* background-color: #ffffff; */
        /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
        /* padding: 20px; */
        /* text-align: center; */
      }
      
      .dots-indicator {
        display: flex;
        gap: 10px;
        margin-top: 1rem;
      }
      
   

      .disabled-arrow {
        pointer-events: none; /* Prevent clicks */
        opacity: 0.5; /* Dim the arrow */
      }
      
  

}

@media only screen and (min-width: 768px) and (max-width: 1023.5px) {

  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
    padding-top: 4vh;
    position: relative; /* Necessary for the ::before positioning */
    overflow: visible; /* Allows the SVG to overflow */
  }
  
  .testimonial-section-main-container::before {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -100px; /* Adjust to overlap the previous section */
    left: 100px; /* Adjust horizontally */
    width: 100px; /* Adjust size of the SVG */
    height: 100px; /* Adjust size of the SVG */
    /* background-image: url('../../../../../new_assets/Svg/CompassSvg.svg');  */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -1; /* Places the SVG behind other content */
  }
  
  .testimonial-section-main-title {
    font-family: 'PRIMARY';
    font-weight: 700;
    font-size: 28px;
    color: rgba(11, 35, 43, 1);
  }
  
  .testimonial-section-testinomial-cards-container {
    display: flex;
    flex-direction: row;
    width: 98%;
    height: 100%;
    padding-top: 4vh;
    justify-content: space-between;
    align-items: center;
  }
  
  .leftandrightarrow {
    display: none;
  }
  
  .testimonial-cards-container-vertiacl-scroll {
    width: 100%;
    overflow: hidden;
    overflow-x: auto ;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }

  body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }


  
  .testimonial-individual-cards {
    display: flex;
    gap: 20px;
    transition: width 0.5s ease; /* Smooth width change */
  }
  
  .dots-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
  
   .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  
  .dot.active {
    background-color: rgba(13, 95, 103, 1);
  }
  /* Set width for cards, you can adjust as needed */
  .testimonial-card {
    width: 30%; /* Adjust width based on screen size */
  }

   /* added by samir */
   .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }
  
  .testimonial-section-main-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    color: #0d5f67;
  }
  
  /* .testimonial-section-testinomial-cards-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
  } */
  
  /* .leftandrightarrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin: 0 10px;
    z-index: 2;
  } */
  
  .testimonial-cards-container-vertiacl-scroll {
    display: flex;
    gap: 20px;
    scroll-snap-type: x mandatory; 
    /* overflow-x: scroll; */
    padding: 10px;
    scroll-behavior: smooth; 
  }
  
  .testimonial-card {
    flex: 0 0 auto;
    /* width: 300px; */
    scroll-snap-align: center;
    /* border-radius: 10px; */
    /* background-color: #ffffff; */
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    /* text-align: center; */
  }
  
  .dots-indicator {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
  }
  
  
  .disabled-arrow {
    pointer-events: none; /* Prevent clicks */
    opacity: 0.5; /* Dim the arrow */
  }
  
 
}

@media only screen and (min-width: 1024px) and (max-width: 1439.5px) {
  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
    padding-top: 4vh;
    position: relative; /* Necessary for the ::before positioning */
    overflow: visible; /* Allows the SVG to overflow */
  }
  
  .testimonial-section-main-container::before {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -10px; /* Adjust to overlap the previous section */
    left: 20%; /* Adjust horizontally */
    width: 30%; /* Adjust size of the SVG */
    height: 34%; /* Adjust size of the SVG */
    /* background-image: url('../../../../new_assets/Svg/CompassSvg.svg');  */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -1; /* Places the SVG behind other content */
  }
  
  .testimonial-section-main-title {
    font-family: 'PRIMARY';
    font-weight: 700;
    font-size: 28px;
    color: rgba(11, 35, 43, 1);
  }
  
  .testimonial-section-testinomial-cards-container {
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 100%;
    padding-top: 4vh;
    justify-content: space-between;
    align-items: center;
  }
  
  .leftandrightarrow {
    width: 4%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-cards-container-vertiacl-scroll {
    width: 80%;
    /* background-color: red; */
    overflow: hidden;
    scroll-behavior: smooth;
  }
  
  .testimonial-individual-cards {
    display: flex;
    gap: 20px;
    transition: width 0.5s ease; /* Smooth width change */
  }
  
  .dots-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .dot.active {
    background-color: rgba(13, 95, 103, 1);
  }
  
  /* Set width for cards, you can adjust as needed */
  .testimonial-card {
    width: 30%; /* Adjust width based on screen size */
  }


  /* added by samir */
  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }
  
  .testimonial-section-main-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    color: #0d5f67;
  }
  
  /* .testimonial-section-testinomial-cards-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
  } */
  
  /* .leftandrightarrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin: 0 10px;
    z-index: 2;
  } */
  
  .testimonial-cards-container-vertiacl-scroll {
    display: flex;
    gap: 20px;
    scroll-snap-type: x mandatory; 
    /* overflow-x: scroll; */
    padding: 10px;
    scroll-behavior: smooth; 
  }
  
  .testimonial-card {
    flex: 0 0 auto;
    /* width: 300px; */
    scroll-snap-align: center;
    /* border-radius: 10px; */
    /* background-color: #ffffff; */
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    /* text-align: center; */
  }
  
  .dots-indicator {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
  }
  
  /* .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #d3d3d3;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #0d5f67;
  } */

  .disabled-arrow {
    pointer-events: none; /* Prevent clicks */
    opacity: 0.5; /* Dim the arrow */
  }
  
  
}

@media only screen and (min-width: 1440px) {
  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
    padding-top: 4vh;
    position: relative; 
    overflow: visible;
  }
  
  .testimonial-section-main-container::before {
    content: ''; /* Necessary for the pseudo-element */
    position: absolute;
    top: -40px; /* Adjust to overlap the previous section */
    left: 20%; /* Adjust horizontally */
    width: 200px; /* Adjust size of the SVG */
    height: 500px; /* Adjust size of the SVG */
    /* background-image: url('../../../../new_assets/Svg/CompassSvg.svg');  */
    background-repeat: no-repeat;
    background-size: contain; /* Ensures the SVG scales proportionally */
    opacity: 1; /* Makes the SVG less prominent */
    z-index: -1; /* Places the SVG behind other content */
  }
  
  .testimonial-section-main-title {
    font-family: PRIMARY;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: rgba(11, 35, 43, 1)
  }
  
  .testimonial-section-testinomial-cards-container {
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 100%;
    padding-top: 4vh;
    justify-content: space-between;
    align-items: center;
  }
  
  .leftandrightarrow {
    width: 4%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-cards-container-vertiacl-scroll {
    width: 80%;
    /* background-color: green; */
    overflow: hidden;
    scroll-behavior: smooth;
  }
  
  .testimonial-individual-cards {
    display: flex;
    gap: 20px;
    transition: width 0.5s ease; /* Smooth width change */
  }
  
  .dots-indicator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .dot.active {
    background-color: rgba(13, 95, 103, 1);
  }
  
  /* Set width for cards, you can adjust as needed */
  .testimonial-card {
    width: 30%; /* Adjust width based on screen size */
  }

  /* added by samir */
  .testimonial-section-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }
  
  .testimonial-section-main-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    color: #0d5f67;
  }
  
  /* .testimonial-section-testinomial-cards-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
  } */
  
  /* .leftandrightarrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin: 0 10px;
    z-index: 2;
  } */
  
  .testimonial-cards-container-vertiacl-scroll {
    display: flex;
    gap: 20px;
    scroll-snap-type: x mandatory; 
    /* overflow-x: scroll; */
    padding: 10px;
    scroll-behavior: smooth; 
  }
  
  .testimonial-card {
    flex: 0 0 auto;
    /* width: 300px; */
    scroll-snap-align: center;
    /* border-radius: 10px; */
    /* background-color: #ffffff; */
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    /* text-align: center; */
  }
  
  .dots-indicator {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
  }
  
 
  .disabled-arrow {
    pointer-events: none; /* Prevent clicks */
    opacity: 0.5; /* Dim the arrow */
  }
  
  
}



/* For mobile, show one card at a time */
@media (max-width: 768px) {
  .testimonial-card {
    width: 90%;
  }
}
