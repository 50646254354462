
@media only screen and (max-width: 767.5px) and (orientation: landscape) {
    .how-it-works-section-container {
      padding-top: 3vh;
      /* padding-bottom: 8vh; */
      position: relative;
      /* background-color: #fff;  */
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .how-it-works-section-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40%; /* Adjust size of the image */
      height: 20%;
      background-image: url('../../../../new_assets/Svg/back.svg');
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1; /* Adjust opacity */
      pointer-events: none; /* Ensures it doesn't interfere with content */
      z-index: -5; /* Ensure it's behind other content */
      /* transform: rotate(330deg); */
    }

    .how-it-works-section-main-title {
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 35px;
      color: rgba(11, 35, 43, 1);
    }
    
    .how-it-works-container-cards-section {
      display: flex;
      width: 80%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* justify-content: space-between; */
      padding-top: 3vh;
      gap: 23px;
    }
 
  }
  
  @media only screen and (max-width: 767.5px) and (orientation: portrait) {
 
    
    .how-it-works-section-container {
      padding-top: 3vh;
      /* padding-bottom: 3vh; */
      position: relative;
      /* background-color: #fff;  */
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .how-it-works-section-container::after {
      content: "";
      position: absolute;
      bottom: 5%;
      right: 0;
      width:40%;
      height: 20%;
      background-image: url('../../../../new_assets/Svg/back.svg');
      
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1; /* Adjust opacity */
      pointer-events: none; /* Ensures it doesn't interfere with content */
      z-index: -5; /* Ensure it's behind other content */
      /* transform: rotate(330deg); */
    }
    
    .how-it-works-section-main-title {
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 30px;
      color: rgba(11, 35, 43, 1);
    }
    
    .how-it-works-container-cards-section {
      display: flex;
      width: 80%;
      /* background-color: red; */
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 2vh;
      /* padding-bottom: 5vh; */
      gap: 27px
      ;
   }
  }
  @media only screen and (min-width: 767px) and (max-width: 1023.5px) {

     .how-it-works-section-container {
      padding-top: 2vh;
      padding-bottom: 3vh;
      position: relative;
      /* background-color: #fff;  */
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .how-it-works-section-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60%; /* Adjust size of the image */
      height: 50%;
      background-image: url('../../../../new_assets/Svg/back.svg');
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1; /* Adjust opacity */
      pointer-events: none; /* Ensures it doesn't interfere with content */
      z-index: -5; /* Ensure it's behind other content */
      /* transform: rotate(330deg); */
    }

    .how-it-works-section-main-title {
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 35px;
      color: rgba(11, 35, 43, 1);
    }
    
    .how-it-works-container-cards-section {
      display: flex;
      width: 90%;
      height: 100%;
      padding-top: 3vh;
      flex-direction: row;
      gap: 10px;
      /* justify-content: space-between; */
      padding-bottom: 5vh;
    }
   
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1439.5px) {

     
    .how-it-works-section-container {
      padding-top: 6vh;
      padding-bottom: 8vh;
      position: relative;
      background-color: #fff; 
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .how-it-works-section-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60%; /* Adjust size of the image */
      height: 50%;
      background-image: url('../../../../new_assets/Svg/back.svg');
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1; /* Adjust opacity */
      pointer-events: none; /* Ensures it doesn't interfere with content */
      z-index: 0; /* Ensure it's behind other content */
      /* transform: rotate(330deg); */
    }
    
    
    .how-it-works-section-main-title {
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 40px;
      color: rgba(11, 35, 43, 1);
    }
    
    .how-it-works-container-cards-section {
      display: flex;
      width: 87%;
      height: 100%;
      padding-top: 3vh;
      gap: 10px;
      /* background-color: green; */
      padding-bottom: 6vh;
      flex-direction: row;
      /* justify-content: space-between; */
    }
   
  }
  
  @media only screen and (min-width: 1440px) {


    .how-it-works-section-container {
      padding-top: 6vh;
      padding-bottom: 8vh;
      position: relative;
      background-color: #fff; 
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .how-it-works-section-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60%; /* Adjust size of the image */
      height: 50%;
      background-image: url('../../../../new_assets/Svg/back.svg');
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1; /* Adjust opacity */
      pointer-events: none; /* Ensures it doesn't interfere with content */
      z-index: 0; /* Ensure it's behind other content */
      /* transform: rotate(330deg); */
    }
    
    .how-it-works-section-main-title {
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 40px;
      color: rgba(11, 35, 43, 1);
    }
    
    .how-it-works-container-cards-section {
      display: flex;
      width: 87%;
      height: 100%;
      gap: 10px;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 3vh;
      padding-bottom: 5vh;
    }
  }
  