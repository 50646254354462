

@media only screen and (max-width: 767.5px) and (orientation: landscape) {

  .testimonial-card {
    display: flex;
    flex-direction: row;
    height: 250px;
    width: 100%;
   
    border-radius: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-text {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
  }
  
  .testimonial-name {
    font-size: 14px;
    font-weight: 700;
    font-family: PRIMARY;
    color: rgba(6, 21, 31, 1);
  }
  
  .testimonial-subtitle {
    font-size: 10px;
    font-weight: 100;
    color: rgba(13, 95, 103, 1);
  }
  
  .testimonial-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    background-color: rgba(13, 95, 103, 0.2);
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    border-radius: 20px;
    padding: 10px;
  }
  
  .testimonial-quote {
    width: 98%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-quote p {
    font-family: "SECONDARY";
    text-align: justify;
    color: rgba(33, 55, 63, 1);
    font-size: 12px;
    text-align: center;
    /* line-height: 2; */
  }
  
  .testimonial-icon {
    /* width: 40%; */
    height: 10%;
     width: 100%;
    display: flex;
    /* justify-content: flex-end;
    align-items: flex-end; */
    justify-content: center;
    align-items: flex-end;
  }
  
  .testimonial-image {
    width: 45%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
  }
  
  .testimonial-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }
  
}

@media only screen and (max-width: 767.5px) and (orientation: portrait) {

  .testimonial-card {
    display: flex;
    height: 270px;
    width: 100%; 
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center;
  }

  .testimonial-text {
    width: 100%;
    /* 55% */
    height: 90%;
    display: flex;
    flex-direction: column;
  }

  .testimonial-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
  }

  .testimonial-name {
    font-size: 12px; 
    font-weight: 700;
    font-family: PRIMARY;
    color: rgba(6, 21, 31, 1);
  }

  .testimonial-subtitle {
    font-size: 10px;
    font-weight: 100;
    color: rgba(13, 95, 103, 1);
  }

  .testimonial-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 100%;
    height: 90%;
    background-color: rgba(13, 95, 103, 0.2);
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    border-radius: 20px;
    padding: 10px;
  }

  .testimonial-quote {
    width: 98%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .testimonial-quote p {
    font-family: "SECONDARY";
    text-align: justify;
    color: rgba(33, 55, 63, 1);
    font-size: 10px;
    text-align: center;
  }

  .testimonial-icon {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* background-color: red; */
    /* justify-content: flex-end;
    align-items: flex-end; */
  }

  .testimonial-image {
    width: 45%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
  }

  .testimonial-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }
}


@media only screen and (min-width: 768px) and (max-width: 1023.5px) {

  .testimonial-card {
    display: flex;
    flex-direction: row;
    height: 250px;
    /* height: 100%; */
    width: 100%;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-text {
    width: 100%;
    /* 55% */
    height: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-header {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
  }
  
  .testimonial-name {
    font-size: 1.2 rem;
    font-weight: 700;
    font-family: PRIMARY;
    color: rgba(6, 21, 31, 1);
  }
  
  .testimonial-subtitle {
    font-size: 10px;
    font-weight: 100;
    color: rgba(13, 95, 103, 1);
  }
  
  .testimonial-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 90%;
    background-color: rgba(13, 95, 103, 0.2);
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    border-radius: 20px;
    padding: 10px;
  }
  
  .testimonial-quote {
    
    width: 98%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-quote p {
    font-family: "SECONDARY";
    text-align: justify;
    color: rgba(33, 55, 63, 1);
    font-size: 13px;
    text-align: center;
    /* background-color: red; */
    /* line-height: 2; */
  }
  
  .testimonial-icon {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* justify-content: flex-end;
    align-items: flex-end; */
  }
  
  .testimonial-image {
    width: 45%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    /* background-color: red; */
  }
  
  .testimonial-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439.5px) {

  .testimonial-card {
    display: flex;
    flex-direction: row;
    height: 400px;
    /* 400px */
    width: 100%;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  
  }
  
  .testimonial-text {
    width: 100%;
    /* 55% */
    height: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-header {
    width: 100%;
    height:30%;
    display: flex;
    justify-content: center;
    align-items: flex-end;    
    /* background-color: red; */
  }
  
  .testimonial-name {
    font-size: 25px;
    /* 20PX */
    font-weight: 700;
    font-family: PRIMARY;
    color: rgba(6, 21, 31, 1);
  }
  
  .testimonial-subtitle {
    font-size: 14px;
    font-weight: 100;
    color: rgba(13, 95, 103, 1);
  }
  
  .testimonial-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 95, 103, 0.2);
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    border-radius: 20px;
    padding: 10px;
  }
  
  .testimonial-quote {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-quote p {
    font-family: "SECONDARY";
    text-align: justify;
    color: rgba(33, 55, 63, 1);
    font-size: 16px;
    text-align: center;
    line-height: 2;
  }
  
  .testimonial-icon {
    width: 40%;
    height: 10%;
    display: flex;
    /* background-color: red; */
    justify-content: center;
    align-items: flex-end;
  }
  
  .testimonial-image {
    width: 45%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }
  
  .testimonial-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }
  
 
}

@media only screen and (min-width: 1440px) {

  .testimonial-card {
    display: flex;
    flex-direction: row;
    height: 400px;
    /* 400px */
    width: 100%;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
  
  }
  
  .testimonial-text {
    width: 100%;
    /* 55% */
    height: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .testimonial-header {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: flex-end;   
  }
  
  .testimonial-name {
    /* font-size: 20px;
    font-weight: 400;
    font-family: "SECONDARY";
    color: rgba(6, 21, 31, 1); */
    font-size: 25px;
    /* 20PX */
    font-weight: 700;
    font-family: PRIMARY;
    color: rgba(6, 21, 31, 1);



  }
  
  .testimonial-subtitle {
    font-size: 14px;
    font-weight: 100;
    color: rgba(13, 95, 103, 1);
  }
  
  .testimonial-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 70%;
    background-color: rgba(13, 95, 103, 0.2);
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
    border-radius: 20px;
    padding: 10px;
    /* background-color: red; */
  }
  
  .testimonial-quote {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-quote p {
    font-family: "SECONDARY";
    text-align: justify;
    color: rgba(33, 55, 63, 1);
    font-size: 16px;
    line-height: 2;
    text-align: center;
  }
  
  .testimonial-icon {
    width: 40%;
    height: 10%;
    display: flex;
    /* background-color: red; */
    justify-content: center;
    align-items: flex-end;
  }
  
  .testimonial-image {
    width: 45%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
  }
  
  .testimonial-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }
  
}

/* 
@media (max-width: 768px) {
  .testimonial-card {
    width: 90%;
  }
} */