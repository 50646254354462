
  
  @media only screen and (max-width: 767.5px) and (orientation: landscape) {

    .get-started-main-container {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: rgba(13, 95, 103, 0.19);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 8vh;
      padding-top: 8vh;
      position: relative;
      overflow: hidden;
      z-index: 0;
    }
  
  
  
    /* .get-started-main-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%; 
      height: 30%;
      background-image: url('../../../../new_assets/img/sty.png'); 
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none; 
      opacity: 0.25;
      z-index: 0;
    
    } */
    
    .get-started-main-title {
      font-family: PRIMARY;
      color: rgba(11, 35, 43, 1);
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
    
    .get-started-main-subtext {
      color: rgba(13, 95, 103, 0.5);
      font-weight: 100;
      font-size: 14px;
      font-family: UNIQUE;
      text-align: center;
    }
    
    .get-started-container-button {
      display: flex;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      font-family: PRIMARY;
      font-size: 20px;
      font-weight: 400;
        background-color: rgba(13, 95, 103, 0.6);
        width: 60%;
      height: 50px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media only screen and (max-width: 767.5px) and (orientation: portrait) {
    .get-started-main-container {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: rgba(13, 95, 103, 0.19);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 4vh;
      padding-top: 4vh;
      position: relative;
      overflow: hidden;
      z-index: 0;
    }
  
  
  
    /* .get-started-main-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30%; 
      height: 30%;
      background-image: url('../../../../new_assets/img/sty.png'); 
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none; 
      opacity: 0.25;
      z-index: 0;
    
    } */
    
    .get-started-main-title {
      font-family: PRIMARY;
      color: rgba(11, 35, 43, 1);
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      width: 98%;
    }
    
    .get-started-main-subtext {
      color: rgba(13, 95, 103, 1);
      font-weight: 100;
      line-height: 2;
      letter-spacing: 1px;
      font-size: 10px;
      width: 98%;
      font-family: UNIQUE;
      text-align: center;
    }
    
    .get-started-container-button {
      display: flex;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      font-family: PRIMARY;
      font-size: 18px;
      font-weight: 400;
        background-color: rgba(13, 95, 103, 0.6);
        width: 60%;
      height: 45px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  
  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023.5px) {

    .get-started-main-container {
      background-color: rgba(13, 95, 103, 0.19);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 3vh;
      padding-top: 3vh;
      position: relative;
      overflow: hidden;
      z-index: 0;
    }
  
  
  
    .get-started-main-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%; 
      height: 50%;
      background-image: url('../../../../new_assets/img/sty.png'); 
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none; 
      opacity: 0.25;
      z-index: 0;
    }
    
    .get-started-main-title {
      font-family: PRIMARY;
      color: rgba(11, 35, 43, 1);
      font-weight: 700;
      font-size: 34px;
      text-align: center;
    }
    
    .get-started-main-subtext {
      color: rgba(13, 95, 103, 0.5);
      width: 90%;
      font-weight: 100;
      font-size: 19px;
      line-height: 2;
      letter-spacing: 1px;
      font-family: UNIQUE;
      text-align: center;
    }
    
    .get-started-container-button {
      display: flex;
      background-color: rgba(13, 95, 103, 0.6);
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      font-family: PRIMARY;
      font-size: 20px;
      font-weight: 400;
      width: 25%;
      height: 45px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
   
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1439.5px) {
    .get-started-main-container {
      background-color: rgba(13, 95, 103, 0.19);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 8vh;
      padding-top: 8vh;
      position: relative;
      overflow: hidden;
      z-index: 0;
    }
  
  
  
    .get-started-main-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%; 
      height: 50%;
      background-image: url('../../../../new_assets/img/sty.png'); 
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none; 
      opacity: 0.25;
      z-index: 0;
    }
    
    .get-started-main-title {
      font-family: PRIMARY;
      color: rgba(11, 35, 43, 1);
      font-weight: 700;
      font-size: 50px;
    }
    
    .get-started-main-subtext {
      color: rgba(13, 95, 103, 0.5);
      font-weight: 100;
      font-size: 20px;
      font-family: UNIQUE;
      text-align: center;
    }
    
    .get-started-container-button {
      display: flex;
      background-color: rgba(13, 95, 103, 0.6);
      height: 60px;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      font-family: PRIMARY;
      font-size: 20px;
      font-weight: 400;
      width: 25%;
      /* height: 40px; */
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media only screen and (min-width: 1440px) {
    .get-started-main-container {
      background-color: rgba(13, 95, 103, 0.19);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 7vh;
      padding-top: 7vh;
      position: relative;
      overflow: hidden;
    }
  
  
  
    .get-started-main-container::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%; 
      height: 50%;
      background-image: url('../../../../new_assets/img/sty.png'); 
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none; 
      opacity: 0.25;
    }
    
    .get-started-main-title {
      font-family: PRIMARY;
      color: rgba(11, 35, 43, 1);
      font-weight: 700;
      font-size: 50px;
    }
    
    .get-started-main-subtext {
      color: rgba(13, 95, 103, 0.5);
      font-weight: 100;
      font-size: 20px;
      font-family: UNIQUE;
    }
    
    .get-started-container-button {
      display: flex;
      background-color: rgba(13, 95, 103, 0.6);
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
      font-family: PRIMARY;
      font-size: 20px;
      font-weight: 400;
      width: 25%;
      height: 60px;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  }
  

