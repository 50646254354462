video::-webkit-media-controls-play-button {
  display: none !important;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}



@media only screen and (max-width: 767.5px) and (orientation: landscape) {
  .landing-screen-a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    width: 100%;

  }
  .line-break-landing {
    display: block;
    content: "\A";
    white-space: pre;
  }

  .red-section{
    opacity: 1;
  }
  
  
  .container-land-a {
    width: 100%;
    height: 100%;
    padding-top: 4vh;
    padding-bottom: 5vh;
    transition: all 0.8s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    /* overflow: hidden; */
    /* background-color: black; */
  }
  
  
  .scrolled-container-a {
    width: 100%;
    height: 100%;
    /* margin-top: -40px; */
  }
  
  
  .background-video {
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    filter: brightness(40%);
  }
  
  /* Video overlay styles */
  .video-overlay {
    z-index: 1;
    height: 100%;
    /* object-fit: cover; */
    /* background-color: red; */
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    position: relative;
    object-fit: cover;
  }
  
  .logo-container{
    height: 10%;
    width: 100%;
    /* background-color: green; */
    align-items: center;
    justify-content: center;
    /* background-color: green; */
    position: relative;
    display: flex;
  }
  
  .extend-logo-container-a{
    height: 10%;
    width: 100%;
    align-items: center;
    position: relative;
    display: flex;
  }
  
  
  .main-cont{
    height: 80%;
    width: 98%;
    /* background-color: pink; */
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .main-heading {
    color: white;
    font-family: 'PRIMARY';
    font-weight: 700;
    font-size: 30px;
    text-align: center;
  }
  
  .highlight {
    background-color: white;
    color: rgba(13, 95, 103, 1);
  }
  
  .sub-heading {
    color: rgba(215, 215, 215, 0.6);
    font-family: 'PRIMARY';
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }
  
  .get-started-button {
    background-color: rgba(13, 95, 103, 1);
    color: white;
    font-family: 'PRIMARY';
    font-size: 20px;
    font-weight: 400;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
  }
  
  
  .red-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .red-section-visible {
    opacity: 1;
  }


}

@media only screen and (max-width: 767.5px) and (orientation: portrait) {
.landing-screen-a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  width: 100%;
}

.line-break-landing {
  display: block;
  content: "\A";
  white-space: pre;
}

.red-section{
  opacity: 1;
}


.container-land-a {
  width: 100%;
  height: 100%;
  padding-bottom: 8vh;
  padding-top: 8vh;
  transition: all 0.8s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
  /* background-color: rgba(0,0,0); */
  /* margin-top: 40px; */
}


.scrolled-container-a {
  width: 100%;
  height: 100%;
}


.background-video {
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: brightness(40%);
}

/* Video overlay styles */
.video-overlay {
  z-index: 1;
  height: 100%;
  /* object-fit: cover; */
  /* background-color: red; */
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  object-fit: cover;
}

.logo-container{
  height: 20%;
  width: 100%;
  /* background-color: green; */
  align-items: center;
  justify-content: center;
  /* background-color: green; */
  position: relative;
  display: flex;
}

.extend-logo-container-a{
  height: 20%;
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
}


.main-cont{
  height: 50%;
  width: 98%;
  /* background-color: pink; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-heading {
  color: white;
  font-family: 'PRIMARY';
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

.highlight {
  background-color: white;
  color: rgba(13, 95, 103, 1);
}

.sub-heading {
  color: rgba(215, 215, 215, 0.6);
  font-family: 'PRIMARY';
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.get-started-button {
  background-color: rgba(13, 95, 103, 1);
  color: white;
  font-family: 'PRIMARY';
  font-size: 20px;
  font-weight: 400;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}


.red-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.red-section-visible {
  opacity: 1;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

.landing-screen-a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* background-color: red; */
  flex-direction: column;
  width: 100%;

}

.line-break-landing {
  display: block;
  content: "\A";
  white-space: pre;
}


.container-land-a {
  width: 100%;
  height: 100%;
  padding-top: 6vh;
  padding-bottom: 6vh;
  transition: all 0.8s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
  /* background-color: black; */
}


.scrolled-container-a {
  width: 100%;
  height: 100%;
  /* margin-top: -40px; */
}


.background-video {
  position: absolute;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: brightness(40%);
}

/* Video overlay styles */
.video-overlay {
  z-index: 1;
  height: 100%;
  /* object-fit: cover; */
  /* background-color: red; */
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
  object-fit: cover;
}

.logo-container{
  height: 25%;
  width: 100%;
  /* background-color: green; */
  align-items: center;
  justify-content: center;
  /* background-color: green; */
  position: relative;
  display: flex;
}

.extend-logo-container-a{
  height: 25%;
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
}


.main-cont{
  height: 60%;
  width: 98%;
  /* background-color: pink; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-heading {
  color: white;
  font-family: 'PRIMARY';
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}

.highlight {
  background-color: white;
  color: rgba(13, 95, 103, 1);
}

.sub-heading {
  color: rgba(215, 215, 215, 0.6);
  font-family: 'PRIMARY';
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  width: 100%;
}

.get-started-button {
  background-color: rgba(13, 95, 103, 1);
  color: white;
  font-family: 'PRIMARY';
  font-size: 20px;
  font-weight: 400;
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}


.red-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.red-section-visible {
  opacity: 1;
}

.red-section{
  opacity: 1;
}

}

@media only screen and (min-width: 1024.5px) and (max-width: 1439.5px) {

.landing-screen-a {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
/* background-color: red; */
flex-direction: column;
width: 100%;
padding: 30px;
transition: all 0.3s ease;
}

.line-break-landing {
  display: none;
}

.red-section{
opacity: 0;
}


.container-land-a {
width: 100%;
border-radius: 10px;
height: 90dvh;
/* height: calc(var(--vh, 0.899vh) * 100); */
margin: auto;
transition: all 0.6s ease-in-out;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction: column;
position: relative;
/* background-color: black; */
}


.scrolled-container-a {
padding: 0px;
}


.background-video {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 10px;
object-fit: cover;
z-index: 0;
filter: brightness(40%);
}

/* Video overlay styles */
.video-overlay {
z-index: 1;
height: 90%;
/* background-color: red; */
width: 100%;
flex-direction: column;
display: flex;
align-items: center;
position: relative;
}

.logo-container{
height: 10%;
width: 100%;
/* background-color: green; */
align-items: center;
position: relative;
display: flex;
}

.extend-logo-container-a{
height: 20%;
width: 100%;
/* background-color: pink; */
align-items: center;
position: relative;
display: flex;
}


.main-cont{
  height: 70%;
  width: 80%;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-heading {
color: white;
font-family: 'PRIMARY';
font-weight: 700;
font-size: 50px;
text-align: center;
}

.highlight {
background-color: white;
color: rgba(13, 95, 103, 1);
}

.sub-heading {
color: rgba(215, 215, 215, 0.6);
font-family: 'PRIMARY';
font-weight: 400;
font-size: 22px;
text-align: center;
}

.get-started-button {
background-color: rgba(13, 95, 103, 1);
color: white;
font-family: 'PRIMARY';
font-size: 20px;
font-weight: 400;
width: 400px;
height: 60px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
cursor: pointer;
position: relative;
box-sizing: border-box;
}


.red-section {
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
background-color: white;
opacity: 0;
transition: opacity 0.3s ease-in-out;
}

.red-section-visible {
opacity: 1;
}
}

@media only screen and (min-width: 1440px) {

  
.line-break-landing {
  display: none;
}

.landing-screen-a {

  display: flex;
justify-content: center;
align-items: center;
height: 100%;
flex-direction: column;
width: 100%;
padding: 30px;
transition: all 0.3s ease;
}

.red-section{
  opacity: 0;

}


.container-land-a {
  width: 100%;
  border-radius: 10px;
  height: 90vh;
  transition: all 0.8s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  /* margin-top: 40px; */
  position: relative;
  /* overflow: hidden; */
  /* background-color: black; */
}


.scrolled-container-a {
  padding: 0px;
}


.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  filter: brightness(40%);
  border-radius: 10px;
}

/* Video overlay styles */
.video-overlay {
  z-index: 1;
  height: 90%;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: relative;
}

.logo-container{
  height: 10%;
  width: 100%;
  /* background-color: green; */
  align-items: center;
  position: relative;
  display: flex;
}

.extend-logo-container-a{
  height: 30%;
  width: 90%;
  /* background-color: pink; */
  align-items: flex-start;
  padding-top: 10px;
  position: relative;
  display: flex;
}


.main-cont{
  height: 70%;
  width: 80%;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main-heading {
  color: white;
  font-family: 'PRIMARY';
  font-weight: 700;
  font-size: 50px;
  text-align: center;
}

.highlight {
  background-color: white;
  color: rgba(13, 95, 103, 1);
}

.sub-heading {
  color: rgba(215, 215, 215, 0.6);
  font-family: 'PRIMARY';
  font-weight: 400;
  font-size: 22px;
  text-align: center;
}

.get-started-button {
  background-color: rgba(13, 95, 103, 1);
  color: white;
  font-family: 'PRIMARY';
  font-size: 20px;
  font-weight: 400;
  width: 400px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
}


.red-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.red-section-visible {
  opacity: 1;
}

}

