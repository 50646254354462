@media only screen and (max-width: 767.5px) and (orientation: landscape) {

  .semi-circle-img.video {
    position: absolute;
        top: 50%;
        left: 0;
        width:34%;
        height:84%;
        transform: translateY(-47%);
        overflow: hidden;
        border-radius: 0 100% 100% 0/0 50% 50% 0;
        
  }
  
  .line-break {
    display: block;
    content: "\A";
    white-space: pre;
  }

  .semi-circle-img.video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
    
  .column-1{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: end;
    /* text-wrap: wrap;
    flex-wrap: wrap; */
    width:auto;
    padding-right: 2%;
  
  
  }
  
  /* .border-left-1 {
    height: auto;
    border-left: 3px solid black;
  } */
  
  .secondary-column-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
    padding-left: 2%;
    text-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    overflow-x: visible;
    position: relative;
    border-left: 3px solid black;
    align-items: flex-start;
  }
  
  
  .column-2{
    display: flex;
    flex-direction: column;
    height: auto;
    text-align: end;
    /* text-wrap: wrap;
    flex-wrap: wrap; */
    width:auto;
    /* width:auto; */
    padding-right: 2%;
  }
  
  
  .border-left-2 {
    height: auto;
    border-left: 3px solid black;
    
  }
  
  
  .secondary-column-2 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: auto;
    width: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    align-items: center;
    overflow-x: visible;
    position: relative;
    padding-left: 2%;
  }
  
  
  .column-text{
    font-family: 'PRIMARY';
    font-weight: 600;
    color: rgba(25, 20, 20, 0.8);
    font-size: 0.67em;
  }
  
  
  
  
  .secondary-text {
    color: rgba(25, 20, 20);
    font-size: 0.5em;
    font-weight: 400;
    font-family: 'PRIMARY';
    letter-spacing: 2px;

  }
  
  .sub-text {
    font-family: 'PRIMARY';
    font-weight: 600;  
    letter-spacing: 2px;
  }
  
  
  
      .line-1 {
        clip-path: inset(0 100% 0% 0);
        animation: revealPath 2s ease-in forwards;
      }
    
    .main-svg{
      width:40%;
    }
      
      .text-1 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 2s ease-in forwards; 
        position: absolute;
        top: 25%;
        left: 32%;
      }
      
      .line-2 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 3s ease-in forwards; 
      }
      
      .text-2 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 4.25s ease-in forwards; 
        position: absolute;
        top: 50%;
        left: 37%;
      }
      
      .line-3 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 5.25s ease-in forwards; 
      }
      
      .text-3 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 6.5s ease-in forwards; 
        position: absolute;
        top: 74%;
        left: 32%;
      }
      
      .line-4 {
        clip-path: inset(0% 0% 0% 100%);
        animation: revealPath 2s 7.5s ease-in forwards; 
      }
      
      @keyframes revealPath {
        100% {
          clip-path: inset(0 0 0 0); 
        }
      }
      
      .semi-circle-container {
        position: relative;
        width: 96%;
        padding-top: 4vh;
        height: 100%;
      }
      
      .semi-circle-text {
        position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: auto;
    /* background-color: red; */
    width: 60%;
      }
  
      
      
      
    
      .video {
        top: 0;
        left: 0;
      }
    
  
    }
    
    
 
    @media only screen and (max-width: 767.5px) and (orientation: portrait) {
  
      .semi-circle-img.video {
        position: absolute;
        top: 50%;
        left: 0;
        width:35%;
        height:70%;
        transform: translateY(-50%);
        overflow: hidden;
        border-radius: 0 100% 100% 0/0 50% 50% 0;
      }
      
      .semi-circle-img.video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

  .column-1{
    /* display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    text-align: end;
    text-wrap: wrap;
    flex-wrap: wrap;
    width:90%;
    padding-right: 2%; */
    display: flex;
    flex-direction: column;
    height: auto;
    /* background-color: green; */
    align-items: flex-start;
    text-align: end;
    /* text-wrap: wrap;
    flex-wrap: wrap; */
    width:auto;
    padding-right: 2%;

  }

  .line-break {
    display: block;
    content: "\A";
    white-space: pre;
  }

  
 
  
  .secondary-column-1 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: auto;
    /* width: 40%; */
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    align-items: center;
    overflow-x: visible;
    position: relative;
    padding-left: 2%;
    
  }
  
  
  .column-2{
    display: flex;
    flex-direction: column;
    height: auto;
    
    align-items: flex-start;
    text-align: end;
    /* text-wrap: wrap;
    flex-wrap: wrap; */
    width:auto;
    padding-right: 2%;

    /* border-right: 3px solid black; */
  
  }
  
  
  /* .border-left-2 {
    height: auto;
    border-left: 5px solid black;
      
  } */

  /* .line-break{
    display: none;
  } */
  
  
  .secondary-column-2 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    /* width: 40%; */
    width: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    align-items: center;
    overflow-x: visible;
    position: relative;
    padding-left: 2%;
  
  }
  
  
  .column-text{
    font-family: 'PRIMARY';
    font-weight: 600;
    color: rgba(25, 20, 20, 0.8);
    font-size: 10px;
    text-align: end;
    
  }
  
  
  
  
  .secondary-text {
    color: rgba(25, 20, 20);
    font-size: 0.5em;
    font-weight: 400;
    font-family: 'PRIMARY';
    letter-spacing: 2px;
  }
  
  .sub-text {
    font-family: 'PRIMARY';
    font-weight: 600;  
    letter-spacing: 2px;
  }
  
  
  
      .line-1 {
        clip-path: inset(0 100% 0% 0);
        animation: revealPath 2s ease-in forwards;
      }
    
    .main-svg{
      width:43%;
      height: 400px;
  
  
    }
      
      .text-1 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 2s ease-in forwards; 
        position: absolute;
        top: 25.5%;
        left: 34%;
      }
      
      .line-2 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 3s ease-in forwards; 
      }
      
      .text-2 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 4.25s ease-in forwards; 
        position: absolute;
        top: 48%;
        left: 39%;
        
      }
      
      .line-3 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 5.25s ease-in forwards; 
      }
      
      .text-3 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 6.5s ease-in forwards; 
        position: absolute;
        top: 70%;
        left: 35%;
      }
      
      .line-4 {
        clip-path: inset(0% 0% 0% 100%);
        animation: revealPath 2s 7.5s ease-in forwards; 
      }
      
      @keyframes revealPath {
        100% {
          clip-path: inset(0 0 0 0); 
        }
      }
      
      .semi-circle-container {
        position: relative;
        width: 96%;
        padding-top: 4vh; 
        padding-bottom: 4vh;
        height: 100%;

      }
      
      .semi-circle-text {
        position: absolute;
        white-space: nowrap;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        height: auto;
        /* background-color: red; */
        width: auto;
      }

      
      
      .video {
        top: 0;
        left: 0;
      }
    
    
    }
    
  
    @media only screen and (min-width: 768px) and (max-width: 1023.5px) {
  
      .semi-circle-img.video {
        position: absolute;
        top: 50%;
        left: 0;
        width:30%;
        height:84%;
        transform: translateY(-50%);
        overflow: hidden;
        border-radius: 0 100% 100% 0/0 50% 50% 0;
      }
      
      .semi-circle-img.video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
  
      .line-break {
        display: block;
        content: "\A";
        white-space: pre;
      }
  
  .container {
    display: flex;
    height: 100%;
  }
  .column-1{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    text-align: end;
    text-wrap: wrap;
    flex-wrap: wrap;
    width:auto;
    padding-right: 2%;
   
  }

    
  .column-2{
    display: flex;
    flex-direction: column;
    height: auto;
    text-align: end;
    text-wrap: wrap;
    flex-wrap: wrap;
    width:40%;
    width:auto;
    padding-right: 2%;
  }
  

  
  .border-left-1 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .secondary-column-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 65%;
    padding-left: 2%;
    text-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    overflow-x: visible;
    position: relative;
    border-left: 3px solid black;
    align-items: flex-start;
  }
  

  
  .border-left-2 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .secondary-column-2 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: 40%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    align-items: center;
    overflow-x: visible;
    position: relative;
    padding-left: 2%;
  }
  
  .column-text {
  
    font-family: 'PRIMARY';
    font-weight: 600;
    color: rgba(25, 20, 20, 0.8);
    font-size: 16px;
  }
  
  
  .secondary-text {
    color: rgba(25, 20, 20);
    font-size: 0.8em;
    font-weight: 400;
    font-family: 'PRIMARY';
    letter-spacing: 2px;
  }
  
  .sub-text {
    font-family: 'PRIMARY';
    font-weight: 600;  
    letter-spacing: 2px;
  }
  
  .semi-circle-text {
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: auto;
    width: 67%;
  }
  
      .line-1 {
        clip-path: inset(0 100% 0% 0);
        animation: revealPath 2s ease-in forwards;
      }
    
    .main-svg{
      width:35%;

      /* height:100%; */
    }
      
      .text-1 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 2s ease-in forwards; 
        position: absolute;
        top: 23%;
        left: 29%;
       
      }
      
      .line-2 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 3s ease-in forwards; 
      }
      
      .text-2 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 4.25s ease-in forwards; 
        position: absolute;
        top: 48.5%;
        left: 33%;
      }
      
      .line-3 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 5.25s ease-in forwards; 
      }
      
      .text-3 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 6.5s ease-in forwards; 
        position: absolute;
        top: 73%;
        left: 28%;
      }
      
      .line-4 {
        clip-path: inset(0% 0% 0% 100%);
        animation: revealPath 2s 7.5s ease-in forwards; 
      }
      
      @keyframes revealPath {
        100% {
          clip-path: inset(0 0 0 0); 
        }
      }
      
  
      .semi-circle-container {
        position: relative;
        width: 95%;
        display: flex;
      }
      
      
      
    
      .video {
        top: 0;
        left: 0;
      }
  
    
  
    
     
    }

    @media only screen and (min-width: 1024px) and (max-width: 1375.5px) {
  
      .semi-circle-img.video {
        position: absolute;
        top: 50%;
        left: 0;
        width:23%;
        height:80%;
        transform: translateY(-50%);
        overflow: hidden;
        border-radius: 0 100% 100% 0/0 50% 50% 0;
      }
      
      .semi-circle-img.video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
  
      .line-break {
        display: block;
        content: "\A";
        white-space: pre;
      }
  
  .container {
    display: flex;
    height: 100%;
  }

  .semi-circle-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
      position: absolute;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      height: auto;
      width: 70%;
  }

  .column-1{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    text-align: end;
    width: auto;
    padding-right: 2%;
   
  
  }
  
  .line-break {
    display: block;
    content: "\A";
    white-space: pre;
  }


  .border-left-1 {
    height: auto;
    border-left: 5px solid black;

  }
  
  .secondary-column-1 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: 60%;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-left: 2%;
    overflow: visible;
    padding-left: 2%;
  }
  
  
  .column-2{
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: end;
    text-wrap: wrap;
    flex-wrap: wrap;
    align-items: flex-start;
    width:auto;
    padding-right: 2%;
  }
  
  
  
  .border-left-2 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .secondary-column-2 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: 60%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    align-items: center;
    overflow-x: visible;
    position: relative;
    align-items: flex-start;
    padding-left: 2%;
  }
  
  .column-text {
    font-family: 'PRIMARY';
    font-weight: 600;
    color: rgba(25, 20, 20, 0.8);
    font-size: 1.4vw;
  }
  
  
  .secondary-text {
    color: rgba(25, 20, 20);
    font-size: 1.2vw;
    font-weight: 400;
    font-family: 'PRIMARY';
    letter-spacing: 2px;
  }
  
  .sub-text {
    font-family: 'PRIMARY';
    font-weight: 600;  
    letter-spacing: 2px;
  }
  
  
      .line-1 {
        clip-path: inset(0 100% 0% 0);
        animation: revealPath 2s ease-in forwards;
      }
    
    .main-svg{
      width:26%;
      /* height:100%; */
     
    }
      
      .text-1 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 2s ease-in forwards; 
        position: absolute;
        top: 22.5%;
        left: 21%;
        
      }
      
      .line-2 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 3s ease-in forwards; 
      }
      
      .text-2 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 4.25s ease-in forwards; 
        position: absolute;
        top: 47.5%;
        left: 24.5%;
      }
      
      .line-3 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 5.25s ease-in forwards; 
      }
      
      .text-3 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 6.5s ease-in forwards; 
        position: absolute;
        top: 72%;
        left: 21%;
      }
      
      .line-4 {
        clip-path: inset(0% 0% 0% 100%);
        animation: revealPath 2s 7.5s ease-in forwards; 
      }
      
      @keyframes revealPath {
        100% {
          clip-path: inset(0 0 0 0); 
        }
      }
      
  
      .semi-circle-container {
        position: relative;
        width: 93.5%;
        display: flex;
      }
      
    
      .video {
        top: 0;
        left: 0;
      }
  

    }
    
   
    @media only screen and (min-width: 1375px) and (max-width: 1439.5px) {
      
      .semi-circle-img.video { 
        position: absolute;
        top: 50%;
        left: 0;
        width:25%;
      height:84%;
        transform: translateY(-50%);
        overflow: hidden;
        border-radius: 0 100% 100% 0/0 50% 50% 0;
      }
      
      .semi-circle-img.video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


      .line-break {
        display: block;
        content: "\A";
        white-space: pre;
      }
  
  .container {
    display: flex;
    height: 100%;
  }
  
  .column-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: end;
    padding-right: 2%;
  }
  
  .column-2 {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: end;
    padding-right: 2%;
    overflow: visible;
  }
  
  .column-text {
  
    font-family: 'PRIMARY';
    font-weight: 600;
    color: rgba(25, 20, 20, 0.8);
    font-size: 24px;
  }
  
  
  
  .border-left-1 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .border-left-2 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .secondary-column-1 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 2%;
    overflow: visible;
  }
  
  .secondary-column-2 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    overflow: visible;
    padding-left: 2%;
  }
  
  .secondary-text {
    color: rgba(25, 20, 20);
    font-size: 1.3vw;
    font-weight: 400;
    font-family: 'PRIMARY';
    letter-spacing: 2px;
  }
  
  .sub-text {
    font-family: 'PRIMARY';
    font-weight: 600;  
    letter-spacing: 2px;
  }
  
  .semi-circle-text {
    display: flex;
    justify-content: center;
    align-items: center;
      position: absolute;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      height: auto;
      width: 80%;

  }
  /*  */
  
      .line-1 {
        clip-path: inset(0 100% 0% 0);
        animation: revealPath 2s ease-in forwards;
      }
    
    .main-svg{
      width:30%;
      /* height:100%; */
      /* background-color: aqua; */
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
      
      .text-1 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 2s ease-in forwards; 
        position: absolute;
        top: 22.5%;
        left: 24%;
      }
      
      .line-2 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 3s ease-in forwards; 
      }
      
      .text-2 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 4.25s ease-in forwards; 
        position: absolute;
        top: 48%;
        left: 28.5%;
      }
      
      .line-3 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 5.25s ease-in forwards; 
      }
      
      .text-3 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 6.5s ease-in forwards; 
        position: absolute;
        top: 72%;
        left: 25%;
      }
      
      .line-4 {
        clip-path: inset(0% 0% 0% 100%);
        animation: revealPath 2s 7.5s ease-in forwards; 
      }
      
      @keyframes revealPath {
        100% {
          clip-path: inset(0 0 0 0); 
        }
      }
      
  
      .semi-circle-container {
        position: relative;
        width: 93.5%;
        height: 100vh;
        display: flex;
      }
      
     
      
    
      .video {
        top: 0;
        left: 0;
      }
    }
    
 
    @media only screen and (min-width: 1440px) {

      .semi-circle-container {
        position: relative;
        width: 93.5%;
        height: 100vh;
        display: flex;
      }
      

      .semi-circle-img.video {
        position: absolute;
        top: 50%;
        left: 0;
        width:25%;
        height:85%;
        transform: translateY(-50%);
        overflow: hidden;
        border-radius: 0 100% 100% 0/0 50% 50% 0;
        /* margin-left: 6.5vw; */

      }
      
      .semi-circle-img.video video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .line-break {
        display: block;
        content: "\A";
        white-space: pre;
      }
  
  .container {
    display: flex;
    height: 100%;
  }
  
  .column-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: end;
    padding-right: 2%;
  }
  
  .column-2 {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: end;
    padding-right: 2%;
  }
  
  .column-text {
  
    font-family: 'PRIMARY';
    font-weight: 600;
    color: rgba(25, 20, 20, 0.8);
    font-size: 1.5vw;
  }
  
  
  
  .border-left-1 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .border-left-2 {
    height: auto;
    border-left: 5px solid black;
  }
  
  .secondary-column-1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    overflow: visible;
    padding-left: 2%;
    border-left: 3px solid black;
  }
  
  .secondary-column-2 {
    display: flex;
    flex-direction: column;
    border-left: 3px solid black;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    overflow: visible;
    padding-left: 2%;
  }
  
  .secondary-text {
    color: rgba(25, 20, 20);
    font-size: 1.3vw;
    font-weight: 400;
    font-family: 'PRIMARY';
    letter-spacing: 2px;
  }
  
  .sub-text {
    font-family: 'PRIMARY';
    font-weight: 600;  
    letter-spacing: 2px;
  }
  
  
  
  
      .line-1 {
        clip-path: inset(0 100% 0% 0);
        animation: revealPath 2s ease-in forwards;
      }
    
    .main-svg{
      width:29%;
      /* height:100%; */
    }
      
      .text-1 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 2s ease-in forwards; 
        position: absolute;
        top: 23%;
        left: 24%;
      }
      
      .line-2 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 3s ease-in forwards; 
      }
      
      .text-2 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 4.25s ease-in forwards; 
        position: absolute;
        top: 48.5%;
        left: 27.5%;
      }
      
      .line-3 {
        clip-path: inset(0 0% 100% 0);
        animation: revealPath 1.25s 5.25s ease-in forwards; 
      }
      
      .text-3 {
        clip-path: inset(0 100% 0% 0); 
        animation: revealPath 1s 6.5s ease-in forwards; 
        position: absolute;
        top: 73%;
        left: 24%;
      }
      
      .line-4 {
        clip-path: inset(0% 0% 0% 100%);
        animation: revealPath 2s 7.5s ease-in forwards; 
      }
      
      @keyframes revealPath {
        100% {
          clip-path: inset(0 0 0 0); 
        }
      }
      
  
      
      .semi-circle-text {
        position: absolute;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        height: auto;
        display: flex;
        width: 65%;
        align-items: center;
      }
      
    
      .video {
        top: 0;
        left: 0;
      }
    
    }

