

  @media only screen and (max-width: 767.5px) and (orientation: landscape) {

    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 5vh;

  
    }

    .footer-anchor-links{
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      color: black;
      line-height: 2;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .footer-anchor-links:hover{
      color: rgba(13, 95, 103);

      /* text-decoration: underline; */
    }
    
    .footer-content {
      display: flex;
      width: 90%;
      flex-direction: column;
      height: 100%;
      align-items: center;
    }
    
    .footer-left {
      order: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .logo {
      width: 185.47px;
    }
    
    .social-media {
      height: 30%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding-bottom: 1vh ;

    }
    
    .copyright {
      height: 20%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .copyright p {
      font-family: SECONDARY;
      font-size: 15px;
      font-weight: 300;
    }
    
    .copyright span {
      font-weight: 500;
    }
    
    .footer-right {
      order:1;
      width: 45%;
      /* background-color: red; */
      height: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 2vh;
    }
    
    .learn, .elearning {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .section-title {
      height: 20%;
      width: 100%;
      display: flex;
      font-family: SECONDARY;
      font-weight: 700;
      font-size: 20px;
    }
    
    .subjects, .links {
      height: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .subjects p, .links p {
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
    }
 
  }
  
  @media only screen and (max-width: 767.5px) and (orientation: portrait) {
    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 5vh;

  
    }

    .footer-anchor-links{
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      color: black;
      line-height: 2;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .footer-anchor-links:hover{
      color: rgba(13, 95, 103);
      /* text-decoration: underline; */
    }
    
    .footer-content {
      display: flex;
      width: 90%;
      flex-direction: column;
      height: 100%;
      align-items: center;
    }
    
    .footer-left {
      order: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
    .logo {
      width: 185.47px;
    }
    
    .social-media {
      height: 30%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding-bottom: 1vh ;

    }
    
    .copyright {
      height: 20%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .copyright p {
      font-family: SECONDARY;
      font-size: 5px;
      font-weight: 300;
    }
    
    .copyright span {
      font-weight: 500;
    }
    
    .footer-right {
      order:1;
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: row;
      padding-bottom: 2vh;
    }
    
    .learn, .elearning {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .section-title {
      height: 20%;
      width: 100%;
      display: flex;
      font-family: SECONDARY;
      font-weight: 700;
      font-size: 20px;
    }
    
    .subjects, .links {
      height: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .subjects p, .links p {
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
    }
 
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023.5px) {
    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 5vh;
  
    }
    
    .footer-content {
      display: flex;
      width: 80%;
      flex-direction: row;
      height: 100%;
    }
    
    .footer-left {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .logo {
      width: 185.47px;
    }
    
    .social-media {
      height: 30%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
    }

    .footer-anchor-links{
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      color: black;
      line-height: 2;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .footer-anchor-links:hover{
      color: rgba(13, 95, 103);
      /* text-decoration: underline; */
    }
    
    .copyright {
      height: 20%;
      width: 100%;
    }
    
    .copyright p {
      font-family: SECONDARY;
      font-size: 15px;
      font-weight: 300;
    }
    
    .copyright span {
      font-weight: 500;
    }
    
    .footer-right {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    
    .learn, .elearning {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .section-title {
      height: 20%;
      width: 100%;
      display: flex;
      font-family: SECONDARY;
      font-weight: 700;
      font-size: 20px;
    }
    
    .subjects, .links {
      height: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .subjects p, .links p {
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1439.5px) {
    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 5vh;
  
    }
    
    .footer-content {
      display: flex;
      width: 80%;
      flex-direction: row;
      height: 100%;
    }
    
    .footer-left {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .logo {
      width: 185.47px;
    }

    .footer-anchor-links{
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      color: black;
      line-height: 2;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .footer-anchor-links:hover{
      color: rgba(13, 95, 103);
      /* text-decoration: underline; */
    }
    
    .social-media {
      height: 30%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
    }
    
    .copyright {
      height: 20%;
      width: 100%;
    }
    
    .copyright p {
      font-family: SECONDARY;
      font-size: 15px;
      font-weight: 300;
    }
    
    .copyright span {
      font-weight: 500;
    }
    
    .footer-right {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    
    .learn, .elearning {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .section-title {
      height: 20%;
      width: 100%;
      display: flex;
      font-family: SECONDARY;
      font-weight: 700;
      font-size: 20px;
    }
    
    .subjects, .links {
      height: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .subjects p, .links p {
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
    }
  }
  
  @media only screen and (min-width: 1440px) {
    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      padding-top: 5vh;
      padding-bottom: 5vh;

  
    }
    
    .footer-content {
      display: flex;
      width: 80%;
      flex-direction: row;
      height: 100%;
    }
    
    .footer-left {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .logo {
      width: 185.47px;
    }
    
    .social-media {
      height: 30%;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 7px;
    }
    
    .copyright {
      height: 20%;
      width: 100%;
    }
    
    .copyright p {
      font-family: SECONDARY;
      font-size: 15px;
      font-weight: 300;
    }
    
    .copyright span {
      font-weight: 500;
    }
    
    .footer-right {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    
    .learn, .elearning {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    
    .section-title {
      height: 20%;
      width: 100%;
      display: flex;
      font-family: SECONDARY;
      font-weight: 700;
      font-size: 20px;
    }
    
    .subjects, .links {
      height: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    
    /* .subjects p, .links p {
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
    } */

    .footer-anchor-links{
      font-family: SECONDARY;
      font-size: 17px;
      font-weight: 500;
      text-decoration: none;
      color: black;
      line-height: 2;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    .footer-anchor-links:hover{
      color: rgba(13, 95, 103);
      fill: rgba(13, 95, 103);
      /* text-decoration: underline; */
    }
  }
  