.App {
 /* width: 100vw;
  height: 100vh;
  background-color: aqua; */
 overflow-x: hidden;
}

.App {
    overflow-y: scroll; /* Keeps content scrollable */
    scrollbar-width: none; /* For Firefox */
}
  
  .App::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }

/*Mobile Devices (portrait and landscape)

@media only screen and (max-width:767px)
{}

Tablet Devices (portrait and landscape)

@media only screen and (min-width:768px) and (max-width:1023px)
{}


Laptop Devices (portrait and landscape)

@media only screen and (min-width:1024px) and (max-width:1439px)
{}


Desktop Devices (portrait and landscape)

@media only screen and (min-width:1440px)
{}

*/
