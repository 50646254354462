
  @media only screen and (max-width: 767.5px) and (orientation: landscape) {

    
.explore-courses-video-card {
    width: 100%;
    /* background-color: pink; */
    height: 202px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden; 
}

.explore-courses-video-card .overlay-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%; 
    background-image: linear-gradient(rgba(217, 217, 217, 0.4), rgba(13, 95, 103, 0.4), rgba(13, 95, 103, 0.4)) ;

    z-index: 2; 
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: height 0.6s ease; 
}

/* .explore-courses-video-card:hover .overlay-wrapper {
    height: 30%; 
} */

.explore-courses-video-card .text-overlay {
    font-size: 22px;
    font-family: PRIMARY;
    font-weight: bold;
    color: white;
    text-align: center;
    z-index: 2; 
    transition: color 0.3s ease-in-out;
    /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); */
}


.custom-video-wrapper {
    position: relative;
    width: 320px;
    height: 240px;
    cursor: pointer;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    /* color: white; */
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .play-pause-icons{
    color: rgba(255, 255, 255, 0.6);
  }

  .play-pause-icons:hover{
    color: white
  }

  }
  
  @media only screen and (max-width: 767.5px) and (orientation: portrait) {
    
.explore-courses-video-card {
    width: 100%;
    /* background-color: pink; */
    height: 202px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden; 
}

.explore-courses-video-card .overlay-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%; 
    background-image: linear-gradient(rgba(217, 217, 217, 0.4), rgba(13, 95, 103, 0.4), rgba(13, 95, 103, 0.4)) ;
    z-index: 2; 
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: height 0.6s ease; 
}

/* .explore-courses-video-card:hover .overlay-wrapper {
    height: 30%; 
} */

.explore-courses-video-card .text-overlay {
    font-size: 22px;
    font-family: PRIMARY;
    font-weight: bold;
    color: white;
    text-align: center;
    z-index: 2; 
    transition: color 0.3s ease-in-out;
    /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); */
}


.custom-video-wrapper {
    position: relative;
    width: 320px;
    height: 240px;
    cursor: pointer;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    /* color: white; */
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .play-pause-icons{
    color: rgba(255, 255, 255, 0.6);
  }

  .play-pause-icons:hover{
    color: white
  }

  
  
  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1020.5px) {

     
.explore-courses-video-card {
  width: 24%;
  /* background-color: pink; */
  height: 202px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden; 
}

.explore-courses-video-card .overlay-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; 
  background-image: linear-gradient(rgba(217, 217, 217, 0.4), rgba(13, 95, 103, 0.4), rgba(13, 95, 103, 0.4)) ;
  z-index: 2; 
  display: flex; 
  justify-content: center;
  align-items: center;
  transition: height 0.6s ease; 
}

.explore-courses-video-card:hover .overlay-wrapper {
  height: 30%; 
}

.explore-courses-video-card .text-overlay {
  font-size: 22px;
  font-family: PRIMARY;
  font-weight: bold;
  color: white;
  text-align: center;
  z-index: 2; 
  transition: color 0.3s ease-in-out;
  /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); */
}


.custom-video-wrapper {
  position: relative;
  width: 320px;
  height: 240px;
  cursor: pointer;
}

video {
  width: 100%;
  height: 100%;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  /* color: white; */
  /* background-color: rgba(0, 0, 0, 0.6); */
  border-radius: 50%;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.play-pause-icons{
  color: rgba(255, 255, 255, 0.6);
}

.play-pause-icons:hover{
  color: white
}


  }


  @media only screen and (min-width: 1021px) and (max-width: 1024.5px) {

     
    .explore-courses-video-card {
      width: 24%;
      /* background-color: pink; */
      height: 202px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      overflow: hidden; 
    }
    
    .explore-courses-video-card .overlay-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30%; 
      background-image: linear-gradient(rgba(217, 217, 217, 0.4), rgba(13, 95, 103, 0.4), rgba(13, 95, 103, 0.4)) ;
      z-index: 2; 
      display: flex; 
      justify-content: center;
      align-items: center;
      transition: height 0.6s ease; 
    }
    
    .explore-courses-video-card:hover .overlay-wrapper {
      height: 30%; 
    }
    
    .explore-courses-video-card .text-overlay {
      font-size: 22px;
      font-family: PRIMARY;
      font-weight: bold;
      color: white;
      text-align: center;
      z-index: 2; 
      transition: color 0.3s ease-in-out;
      /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); */
    }
    
    
    .custom-video-wrapper {
      position: relative;
      width: 320px;
      height: 240px;
      cursor: pointer;
    }
    
    video {
      width: 100%;
      height: 100%;
      display: block;
    }
    
    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 48px;
      /* color: white; */
      /* background-color: rgba(0, 0, 0, 0.6); */
      border-radius: 50%;
      padding: 10px 20px;
      cursor: pointer;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
    
    .play-pause-icons{
      color: rgba(255, 255, 255, 0.6);
    }
    
    .play-pause-icons:hover{
      color: white
    }
    
    
      }


  
  @media only screen and (min-width: 1025px) and (max-width: 1439.5px) {
    
.explore-courses-video-card {
    width: 24%;
    /* background-color: pink; */
    height: 202px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden; 
}

.explore-courses-video-card .overlay-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: linear-gradient(rgba(217, 217, 217, 0.4), rgba(13, 95, 103, 0.4), rgba(13, 95, 103, 0.4)) ;
    z-index: 2; 
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: height 0.6s ease; 
}

.explore-courses-video-card:hover .overlay-wrapper {
    height: 30%; 
}

.explore-courses-video-card .text-overlay {
    font-size: 22px;
    font-family: PRIMARY;
    font-weight: bold;
    color: white;
    text-align: center;
    z-index: 2; 
    transition: color 0.3s ease-in-out;
    /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); */
}


.custom-video-wrapper {
    position: relative;
    width: 320px;
    height: 240px;
    cursor: pointer;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    /* color: white; */
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .play-pause-icons{
    color: rgba(255, 255, 255, 0.6);
  }

  .play-pause-icons:hover{
    color: white
  }

  }
  
  @media only screen and (min-width: 1440px) {
    
.explore-courses-video-card {
    width: 24%;
    /* background-color: pink; */
    height: 202px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden; 
}

.explore-courses-video-card .overlay-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-image: linear-gradient(rgba(217, 217, 217, 0.4), rgba(13, 95, 103, 0.4), rgba(13, 95, 103, 0.4)) ;
    z-index: 2; 
    display: flex; 
    justify-content: center;
    align-items: center;
    transition: height 0.6s ease; 
}

.explore-courses-video-card:hover .overlay-wrapper {
    height: 30%; 
}

.explore-courses-video-card .text-overlay {
    font-size: 22px;
    font-family: PRIMARY;
    font-weight: bold;
    color: white;
    text-align: center;
    z-index: 2; 
    transition: color 0.3s ease-in-out;
    /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4); */
}


.custom-video-wrapper {
    position: relative;
    width: 320px;
    height: 240px;
    cursor: pointer;
  }

  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    /* color: white; */
    /* background-color: rgba(0, 0, 0, 0.6); */
    border-radius: 50%;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .play-pause-icons{
    color: rgba(255, 255, 255, 0.6);
  }

  .play-pause-icons:hover{
    color: white
  }

  }
  