
  .neew{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title_text_1{
    letter-spacing: 1.7px;
    font-size: 1.2vw;
        width: 80%;
        font-weight: 700;
        font-family: POPPINS;
  
  }
  
  .title_text_2{
    font-size: 1.1vw;
    font-weight: 100;
    font-family: UNIQUE;
    width: 70%;
  }

.insight-Container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(86, 143, 149, 0.12);
}

.insight-Container::before {
  content: '';
  position: absolute;
  top: 10vh; 
  left: -50px; 
  width: 10%; 
  height: 100%; 
  background-image: url('../../../../new_assets/Svg/Phy.Svg');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1; /* Make the image less opaque */
  z-index: 0; /* Ensure it stays in the background */
  pointer-events: none; /* Prevent interaction with the background */
}

.landingPageSection {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* padding-bottom: 4vh; */
}



.landingPageSection1part1{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* padding-bottom: 8vh; */
 /* padding-top: 4vh */
}

.landingPageSection1Part2{
  flex-direction: column;
  display: flex;
  width: 80%;
  height: 100%;
  align-items: center
}



.landingPageSection1Part2SubHeading{
  font-family: PRIMARY;
font-weight: 100;
font-size: 12px;
text-align: center;
color: rgba(6, 21, 31, 1);
}


.heading {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: rgba(11, 35, 43, 0.9);
}

.subHeading {
  text-align: center;
  font-size: 0.89rem;
  color: rgba(6, 21, 31, 1);
  margin-bottom: 2rem;
  padding: 0 11vw 0 11vw;
  text-align: center;
  font-weight: 400;
  color: rgba(6, 21, 31, 1);
  margin-bottom: 2rem;
  line-height: 1.6;
  /* padding: 0 11vw 0 11vw; */
  font-family:POPPINS ;
  letter-spacing: 2px;
}


/* .tabs {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  border-bottom: 2px solid rgba(2, 28, 43, 1);
} */

.tab {
  background-color: transparent;
  /* border: none; */
  /* font-size: 24px; */
  font-weight: 100;
  /* font-family: POPPINS; */
  /* width: 20%; */
  color: rgba(11, 35, 43, 1);
  /* padding: 0.5rem 1rem; */
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.activeTab {
  font-family: POPPINS;
  border-bottom: 2px solid #000;
  font-weight: bold;
}

/* Content Row */
.contentRow {
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;
  gap: 1.5rem;
  height: 100%;
}

.infoColumn {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
}

.infoBox {
  /* padding: 1rem; */
  /* margin-bottom: 1rem; */
  border-bottom: 2px solid rgba(2, 28, 43, 1);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option_title_cont {
  display: flex;
  justify-content: space-between;
}

.title_text{
  font-size: 1.4vw;
  text-align: center;
}

.activeInfoBox {
  background-color: rgba(13, 95, 103, 0.2);
  /* border-bottom: 4px solid rgba(2, 28, 43, 1); */
  border-bottom: none;
  color: rgba(13, 95, 103, 1);
  height: auto;
  /* padding-top:2px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard {
  /* width: 70%; */
  width: 100%;    
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.img-cont,
.video-cont {
  width: 100%;
  height: 70vh;
  /* max-height: 100%; */
  object-fit: contain;
  border-radius: 8px;
}

.contentArrange{
  /* height:70vh;  */
  width:100%;
}

/* Style the video container */
.dashboard1 {
  position: relative;
}

/* Style the custom spinner */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;
  border: 4px solid rgba(13, 95, 103, 0.19); /* Outer ring */
  border-top: 4px solid rgba(13, 95, 103, 1); /* Color of the spinner */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

video::-webkit-media-controls {
  visibility: hidden;
}

video::-moz-media-controls {
  visibility: hidden !important;
}

video::-webkit-media-controls-enclosure {
  visibility: visible;
}

video::-moz-media-controls {
  visibility: visible !important;
}




@media only screen and (max-width: 767.5px) and (orientation: landscape) {

  
    .insight-Container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba(86, 143, 149, 0.12);
      /* padding-top: 8vh; */
      padding-top: 4vh;
      padding-bottom: 3vh;
    }
    
    .contentArrange{
      height:100%; 
      width:100%;
  }

    .neew{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .insight-Container::before {
      content: '';
      position: absolute;
      top: 5px; 
      left: -50px; 
      width: 30%; 
      height: 100%; 
      background-image: url('../../../../new_assets/Svg/Phy.Svg');
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.6; /* Make the image less opaque */
      z-index: 0; /* Ensure it stays in the background */
      pointer-events: none;
    }
    
    .landingPageSection1part1{
      display: flex;
      width: 95%;
      height: 100%;
      justify-content: center;
      padding-bottom: 8vh;
     padding-top: 4vh;
    }
    
    .landingPageSection1Part2{
      flex-direction: column;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center
    }
    
    .landingPageSection1Part2Heading{
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 20px;
      width: 90%;
      text-align: center;
      color: rgba(11, 35, 43, 1);
    }
    
    .landingPageSection1Part2SubHeading{
      font-family: POPPINS;
      font-weight: 575;
      font-size: 12px;
      text-align: center;
      color: rgba(6, 21, 31, 1);
      line-height: 2;
      letter-spacing: 1.2px;
      width: 90%;
      text-align: center;
    }
    
    
    .heading {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1rem;
      color: rgba(11, 35, 43, 0.9);
    }
    
    .subHeading {
      text-align: center;
      font-size: 0.89rem;
      color: rgba(6, 21, 31, 1);
      margin-bottom: 2rem;
      padding: 0 11vw 0 11vw;
      text-align: center;
      font-weight: 400;
      color: rgba(6, 21, 31, 1);
      margin-bottom: 2rem;
      line-height: 1.6;
      /* padding: 0 11vw 0 11vw; */
      font-family:POPPINS ;
      letter-spacing: 2px;
    }
    
    /* Tabs */
    .tabs {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 1px;
      margin-bottom: 2rem;
      flex-wrap: wrap;
      border-bottom: 2px solid rgba(2, 28, 43, 1);
    }
    
    .tab {
      background-color: transparent;
      border: none;
      font-size: clamp(14px,2.3vw,30px); 
      /* font-size: 10px;  */
      font-family: PRIMARY;
      width: 23%;
      color: rgba(11, 35, 43, 1);
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
    
    .activeTab {
      font-family: PRIMARY;
      border-bottom: 2px solid #000;
      font-weight: bold;
    }
    
    /* Content Row */
    .contentRow {
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      gap: 1.5rem;
    }
    
    .infoColumn {
      display: flex;
      flex-direction: column;
      width: 100%
    }
    
    .infoBox {
      padding: 1rem;
      margin-bottom: 1rem;
      border-bottom: 2px solid rgba(2, 28, 43, 1);
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    
    .option_title_cont {
      display: flex;
      justify-content: space-between;
      width: 90%;
    }
    
    .title_text_1{
      font-size: 20px;
      letter-spacing: 1.7px;
        width: 80%;
        font-weight: 700;
        font-family: POPPINS;
    }
    
    .title_text_2{
      font-size: 15px;
      font-weight: 100;
      font-family: UNIQUE;
      width: 90%;
    }
    
    .activeInfoBox {
      background-color: rgba(13, 95, 103, 0.2);
      /* border-bottom: 4px solid rgba(2, 28, 43, 1); */
      border-bottom: none;
      color: rgba(13, 95, 103, 1);
    }
    
    .dashboard {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;
    }
    
    .imgcont,
    .video-cont{
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }

    

    .dashboard1 {
 
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 60vh;
      display: flex;
      align-items: flex-start;
      /* height: auto; */
    }

    .img-cont-tab{
      width: 100%;
      height: 50vh;
      /* background-color: red; */
      object-fit: contain;
      border-radius: 8px;
    }



    }


    
    @media only screen and (max-width: 767.5px) and (orientation: portrait) {
      

      .spinner {
        top: 60%;
      }

      .contentArrange{
        height:100%; 
        width:100%;
    }
    
      .neew{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    
    .insight-Container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba(86, 143, 149, 0.12);
      padding-top: 4vh;
      padding-bottom: 0.5vh;
    }
    
    .insight-Container::before {
      content: '';
      position: absolute;
      top: 10px; 
      left: -70px; 
      width: 50%; 
      height: 100%; 
      background-image: url('../../../../new_assets/Svg/Phy.Svg');

      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.5; /* Make the image less opaque */
      z-index: 0; /* Ensure it stays in the background */
      pointer-events: none; /* Prevent interaction with the background */
    }
    
    .landingPageSection1part1{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
     /* padding-top: 4vh; */
    }
    
    .landingPageSection1Part2{
      flex-direction: column;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center
    }
    
    .landingPageSection1Part2Heading{
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 20px;
      width: 90%;
      text-align: center;
      color: rgba(11, 35, 43, 1);
    }
    
    .landingPageSection1Part2SubHeading{
      font-family: POPPINS;
      font-weight: 575;
      font-size: 14px;
      text-align: center;
      color: rgba(6, 21, 31, 1);
      line-height: 1.5;
      letter-spacing: 0.5px;
      width: 96%;
      text-align: center;
    }
    
    
    .heading {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1rem;
      color: rgba(11, 35, 43, 0.9);
    }
    
    .subHeading {
      text-align: center;
      font-size: 0.89rem;
      width: 100%;
      color: rgba(6, 21, 31, 1);
      margin-bottom: 2rem;
      text-align: center;
      font-weight: 400;
      color: rgba(6, 21, 31, 1);
      /* margin-bottom: 2rem; */
      line-height: 1.6;
      font-family:POPPINS ;
      letter-spacing: 2px;
    }
    
    /* Tabs */
    .tabs {
      width: 100%;
      display: flex;
      justify-content: space-around;
      /* gap: 10px; */
      margin-bottom: 2rem;
      flex-wrap: wrap;
      border-bottom: 2px solid rgba(2, 28, 43, 1);
      /* background-color: rgb(0, 255, 162); */
    }
    
    .tab {
      background-color: transparent;
      border: none;
      /* background-color: red; */
      font-size: 0.65rem;
      /* flex-wrap: wrap; */
      /* font-size: 10px;  */
      font-family: PRIMARY;
      width: auto;
      color: rgba(11, 35, 43, 1);
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
    
    .activeTab {
      font-family: PRIMARY;
      border-bottom: 2px solid #000;
      font-weight: bold;
    }
    
    /* Content Row */
    .contentRow {
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      gap: 1.5rem;
      width: 100%;
    }
    
    .infoColumn {
      display: flex;
      flex-direction: column;
      /* width: 30%; */
      height: 100%;
      width: 100%;
    }
    
    .infoBox {
      /* padding: 1rem; */
      /* margin-bottom: 1rem; */
      height: 70px;
      border-bottom: 2px solid rgba(2, 28, 43, 1);
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    
    .option_title_cont {
      display: flex;
      justify-content: space-between;
      width: 90%;
    }
    
    .title_text_1{
      font-size: 0.8rem;
      letter-spacing: 1.7px;
        width: 80%;
        font-weight: 700;
        font-family: POPPINS;
    }
    
    .title_text_2{
      font-size: 15px;
      font-weight: 100;
      font-family: UNIQUE;
      width: 90%;
    }
    
    .activeInfoBox {
      background-color: rgba(13, 95, 103, 0.2);   
      /* border-bottom: 4px solid rgba(2, 28, 43, 1); */
      border-bottom: none;
       color: rgba(13, 95, 103, 1); 
       padding-top: 20px;
       padding-bottom: 20px;
      width: 100%;
      height: 100%;
      
    }
    
    .dashboard {
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;
    }
    
    .img-cont-tab{
      width: 90%;
      height: 40vh;
      object-fit: contain;
      border-radius: 8px;
    }

    .img-cont,
    .video-cont {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }

    .contentArrange{
      height:100%; 
      /* background-color: blue; */
      width:100%;
  }

  .dashboard1 {
 
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 60vh;
    background-color: aliceblue;
    display: flex;
    align-items: flex-start;
    /* height: auto; */
  }



  
    
    }


    @media only screen and (min-width: 768px) and (max-width: 1024.5px) {


      .spinner {
        top: 55%;
      }

      .img-cont-tab{
        width: 100%;
        height: 370px;
        object-fit: contain;
        border-radius: 8px;
      }

      .dash{
      height:50vh;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      /* background-color: blue; */
    }
   
      .insight-Container {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: rgba(86, 143, 149, 0.12);
        z-index: 3;
        /* background-color: red; */
        /* padding-top: 4vh; */
        /* padding-bottom: 3vh; */
        
      }
      
      .contentArrange{
        height:100%; 
        width:100%;
    }
  
      .neew{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .insight-Container::before {
        content: '';
        position: absolute;
        top: 10vh; 
        left: -50px; 
        width: 10%; 
        height: 100%; 
        background-image: url('../../../../new_assets/Svg/Phy.Svg');
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 1; /* Make the image less opaque */
        z-index: 0; /* Ensure it stays in the background */
        pointer-events: none;
      }
      
      .landingPageSection1part1{
        display: flex;
        width: 95%;
        height: 100%;
        justify-content: center;
        padding-bottom: 1vh;
       padding-top: 4vh;
      }
      
      .landingPageSection1Part2{
        flex-direction: column;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center
      }
      
      .landingPageSection1Part2Heading{
        font-family: PRIMARY;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        color: rgba(11, 35, 43, 1);
      }
      
      .landingPageSection1Part2SubHeading{
        font-family: POPPINS;
        font-weight: 575;
        font-size: 17px;
        text-align: center;
        color: rgba(6, 21, 31, 1);
        line-height: 2;
        letter-spacing: 1.2px;
        width: 90%;
        text-align: center;
      }
      
      
      .heading {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
        color: rgba(11, 35, 43, 0.9);
      }
      
      .subHeading {
        text-align: center;
        font-size: 0.89rem;
        color: rgba(6, 21, 31, 1);
        margin-bottom: 2rem;
        padding: 0 11vw 0 11vw;
        text-align: center;
        font-weight: 400;
        color: rgba(6, 21, 31, 1);
        margin-bottom: 2rem;
        line-height: 1.6;
        /* padding: 0 11vw 0 11vw; */
        font-family:POPPINS ;
        letter-spacing: 2px;
      }
      
      /* Tabs */
      .tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        /* background-color: red; */
        gap: 1px;
        margin-bottom: 2rem;
        flex-wrap: wrap;
        border-bottom: 2px solid rgba(2, 28, 43, 1);
      }
      
      .tab {
        background-color: transparent;
        border: none;
        font-size: clamp(14px,2.3vw,30px); 
        font-family: PRIMARY;
        width: 23%;
        color: rgba(11, 35, 43, 1);
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }
      
      .activeTab {
        font-family: PRIMARY;
        border-bottom: 2px solid #000;
        font-weight: bold;
      }
      
      /* Content Row */
      .contentRow {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;
        gap: 1.5rem;
      }
      
      .infoColumn {
        display: flex;
        flex-direction: column;
        width: 100%
      }

      .dashboard1 {
 
        border-radius: 10px;
        object-fit: cover;
        width: 100%;
        height: 60vh;
        display: flex;
        align-items: flex-start;
        /* height: auto; */
      }
  
  
  
      
      .infoBox {
         padding: 1rem;
         margin-bottom: 1rem;
        border-bottom: 2px solid rgba(2, 28, 43, 1);
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      
      .option_title_cont {
        display: flex;
        justify-content: space-between;
        width: 90%;
      }
      
      .title_text_1{
        font-size: 20px;
        
        letter-spacing: 1.7px;
        width: 80%;
        font-weight: 700;
        font-family: POPPINS;
      }
      
      .title_text_2{
        font-size: 15px;
        font-weight: 100;
        font-family: UNIQUE;
        width: 90%;
      }
      
      .activeInfoBox {
        background-color: rgba(13, 95, 103, 0.2);
        /* border-bottom: 4px solid rgba(2, 28, 43, 1); */
        border-bottom: none;
        color: rgba(13, 95, 103, 1);
      }
      
      .dashboard {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
      }
      
      .imgcont,
      .video-cont{
        width: 100%;
        height: auto;
        max-height: 100%;
        object-fit: contain;
        border-radius: 8px;
      }
  
    }
    
    @media only screen and (min-width: 1025px) and (max-width: 1439.5px) {
      .neew{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .title_text_1{
        font-size: 1em;
        letter-spacing: 1.7px;
        width: 80%;
        font-weight: 700;
        font-family: POPPINS;
        
      }
      
      .title_text_2{
        font-size: 1.1vw;
        font-weight: 100;
        font-family: UNIQUE;
        width:90%;
        text-align: start;
      }
    
    .insight-Container {
      /* background-color: red; */
      position: relative;
      width: 100%;
      height: 100%;
      /* background-color: rgba(86, 143, 149, 0.12); */
      padding-top: 2vh;
      padding-bottom: 3vh;
      z-index: 10;
    
    }
    
    .insight-Container::before {
      content: '';
      position: absolute;
      top: 10vh; 
      left: -80px; 
      width: 20%; 
      height: 100%; 
      background-image: url('../../../../new_assets/Svg/Phy.Svg');
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1; /* Make the image less opaque */
      z-index: 0; /* Ensure it stays in the background */
      pointer-events: none; /* Prevent interaction with the background */
    }
    
    .landingPageSection {
      position: relative;
      z-index: 1; /* Keeps content above the background image */
      width: 100%;
      height: 100%;
      margin-top: 3vh;
      /* padding-bottom: 4vh; */
      /* background-color: blue; */
    }
    
    .landingPageSection1part1{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      /* background-color: green; */
      /* padding-bottom: 8vh; */
     padding-top: 4vh,
    }
    
    .landingPageSection1Part2{
      flex-direction: column;
      display: flex;
      width: 88%;
      /* background-color: red; */
      height: 100%;
      align-items: center
    }
    
    .landingPageSection1Part2Heading{
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 42px;
      text-align: center;
      color: rgba(11, 35, 43, 1);
    }
    
    .landingPageSection1Part2SubHeading{
      font-family: POPPINS;
    font-weight: 575;
    font-size: 17px;
    text-align: center;
    color: rgba(6, 21, 31, 1);
    line-height: 2;
    letter-spacing: 1.2px;
    }
    
    
    
    .heading {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1rem;
      color: rgba(11, 35, 43, 0.9);
    }
    
    .subHeading {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: rgba(6, 21, 31, 1);
      margin-bottom: 2rem;
      line-height: 1.6;
      /* padding: 0 11vw 0 11vw; */
      font-family:POPPINS ;
      letter-spacing: 2px;
    }
    
    /* Tabs */
    .tabs {

      /* background-color: red; */
      display: flex;
      justify-content: space-around;
      gap: 2rem;
      margin-bottom: 2rem;
      flex-wrap: wrap;
      border-bottom: 2px solid rgba(2, 28, 43, 1);

    }
    
    .tab {
      background-color: transparent;
      border: none;
      font-size: 28px;
      font-family: POPPINS;
      font-weight: 300;
      width: auto;
      /* background-color: green; */
      color: rgba(11, 35, 43, 1);
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
    
    .activeTab {
      font-family: POPPINS;
      border-bottom: 2px solid #000;
      font-weight: bold;
    }
    
    /* Content Row */
    .contentRow {
      display: flex;
      align-items: flex-start;
      /* margin-top: 2rem; */
      height: 100%;
      gap: 1.5rem;
    }
    
    .infoColumn {
      display: flex;
      flex-direction: column;
      width: 35%;
      height: 100%;
    }
    
    .infoBox {
      
      border-bottom: 2px solid rgba(2, 28, 43, 1);
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* text-align: center; */
      transition: all 0.3s ease;
    }
    
    .option_title_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
    }
    
    .title_text{
      font-size: 1.4vw;
    }
    
    .activeInfoBox {
      background-color: rgba(13, 95, 103, 0.2);
      border-bottom: none;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100%;
      /* border-bottom: 4px solid rgba(2, 28, 43, 1); */
      color: rgba(13, 95, 103, 1);
      width: 100%;
      /* height: 140px; */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
    }
    
    .dash{
      height:70vh;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center
    }
    
    .dashboard {
      width: 100%;    
      /* ms */
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;
    }

    .contentArrange{
        height:100%; 
        /* background-color: red; */
        width:100%;
    }

    .dashboard1 {
 
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 70vh;
      display: flex;
      align-items: flex-start;
      /* height: auto; */
    }
    
    .img-cont,
    .video-cont {
      width: 100%;
      height: 70vh;
      max-height: 100%;
      object-fit: contain;
      border-radius: 8px;
    
  }
  

    }
    
    @media only screen and (min-width: 1440px) {
      
      .neew{
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .dash{
        height:60vh;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center
      }
    
      .title_text_1{
        /* background-color: red; */
        /* font-size: 1.2vw; */
        font-size: 20px;
        letter-spacing: 1.7px;
        width: 90%;
        font-weight: 700;
        font-family: POPPINS;
        
      }
      
      .title_text_2{
        font-size: 1.1vw;
        font-weight: 100;
        font-family: UNIQUE;
        width:90%;
        text-align: start;
      }
    
    .insight-Container {
      /* background-color: red; */
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 2vh;
      padding-bottom: 3vh;
    
    }
    
    .insight-Container::before {
      content: '';
      position: absolute;
      top: 10vh; 
      left: -80px; 
      width: 20%; 
      height: 100%; 
      background-image: url('../../../../new_assets/Svg/Phy.Svg');
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1; /* Make the image less opaque */
      z-index: 0; /* Ensure it stays in the background */
      pointer-events: none; /* Prevent interaction with the background */
    }
    
    .landingPageSection {
      position: relative;
      z-index: 1; /* Keeps content above the background image */
      width: 100%;
      height: 100%;
      margin-top: 3vh;
      /* padding-bottom: 4vh; */
      /* background-color: blue; */
    }
    
    .landingPageSection1part1{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      /* background-color: green; */
      /* padding-bottom: 8vh; */
     padding-top: 4vh,
    }
    
    .landingPageSection1Part2{
      flex-direction: column;
      display: flex;
      width: 88%;
      /* background-color: red; */
      height: 100%;
      align-items: center
    }
    
    .landingPageSection1Part2Heading{
      font-family: PRIMARY;
      font-weight: 700;
      font-size: 42px;
      text-align: center;
      color: rgba(11, 35, 43, 1);
    }
    
    .landingPageSection1Part2SubHeading{
      font-family: POPPINS;
    font-weight: 575;
    font-size: 17px;
    text-align: center;
    color: rgba(6, 21, 31, 1);
    line-height: 2;
    letter-spacing: 1.2px;
    }
    
    
    
    .heading {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: 1rem;
      color: rgba(11, 35, 43, 0.9);
    }
    
    .subHeading {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: rgba(6, 21, 31, 1);
      margin-bottom: 2rem;
      line-height: 1.6;
      /* padding: 0 11vw 0 11vw; */
      font-family:POPPINS ;
      letter-spacing: 2px;
    }
    
    /* Tabs */
    .tabs {

      /* background-color: red; */
      display: flex;
      justify-content: space-around;
      gap: 2rem;
      margin-bottom: 2rem;
      flex-wrap: wrap;
      border-bottom: 2px solid rgba(2, 28, 43, 1);

    }
    
    .tab {
      background-color: transparent;
      border: none;
      font-size: 28px;
      font-family: POPPINS;
      font-weight: 300;
      width: auto;
      /* background-color: green; */
      color: rgba(11, 35, 43, 1);
      padding: 0.5rem 1rem;
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }
    
    .activeTab {
      font-family: POPPINS;
      border-bottom: 2px solid #000;
      font-weight: bold;
    }
    
    /* Content Row */
    .contentRow {
      display: flex;
      align-items: flex-start;
      /* margin-top: 2rem; */
      height: 100%;
      gap: 1.5rem;
    }
    
    .infoColumn {
      display: flex;
      flex-direction: column;
      width: 35%;
      height: 100%;
    }
    
    .infoBox {
      
      border-bottom: 2px solid rgba(2, 28, 43, 1);
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* text-align: center; */

      transition: all 0.3s ease;
    }
    
    .option_title_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
    }
    
    .title_text{
      font-size: 1.4vw;
    }
    
    .activeInfoBox {
      background-color: rgba(13, 95, 103, 0.2);
      border-bottom: none;
      
      /* border-bottom: 4px solid rgba(2, 28, 43, 1); */
      color: rgba(13, 95, 103, 1);
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100%;
      /* height: 155px; */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
    }
    
    .dashboard {
      width: 100%;    
      /* ms */
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;
    }

    .contentArrange{
        height:100%; 
        /* background-color: red; */
        width:100%;
    }
    
    .img-cont,
    .video-cont {
      width: 100%;
      height: 70vh;
      max-height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }

    .dashboard1 {
 
      border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 60vh;
      display: flex;
      align-items: flex-start;
      /* height: auto; */
    }




    }
    
    
    
    
   
  
    .loader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    








  
    /* Remove the top border for the first tab */
    .firstInfoBox {
      border-top: none !important;
    }
    
    /* Remove the bottom border for the last tab */
    .lastInfoBox {
      padding-top: 10px;
      border-bottom: none !important;
    }
    
    /* Remove top and bottom border if active */
    .activeInfoBox {
      border-top: none !important;
      border-bottom: none !important;
    }

    .activePrevious{
      border-bottom: none;
    }