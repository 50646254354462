:root {
 --bg-color: #ffffff;
 --primary-color: #7445ff;
 --secondary-color: #e7dff4;
 --text-color: #000000;
 --inside-primary-color: #ffffff;
 --text-input-color: #000000;
 --primary-color-click: #240977;
 --section_space: 60px;
 --shadow-color_1: #d9d9d9;
 --shadow-color_2: #d9d9d9;
 --header-bottom-space: 6%;
}
/*WEB_FONT to use as common font for the application 
current web font is "Montserrat" */
@font-face {
 font-family: WEB_FONT;
 src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
/*WEB_FONT_Bold to use as common bold font for the application 
current web font is "Montserrat" */
@font-face {
 font-family: WEB_FONT_BOLD;
 src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
 font-family: POPPINS;
 src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: PRIMARY;
    src: url('./new_assets/fonts/Poppins/Poppins-Medium.ttf') format("truetype");
   }
   @font-face {
    font-family: SECONDARY;
    src: url("./new_assets/fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
   }
  
   @font-face {
    font-family: UNIQUE;
    src: url("./new_assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
   }
