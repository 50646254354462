

  @media only screen and (max-width: 766.5px) and (orientation: landscape) {

    
    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(13, 95, 103, 1);
      padding-top: 15px;
      border-radius: 5px;
      width: 281px;
      height: 159px;
    }
    
    .card-content {
      width: 90%;
      height: 100%;
      text-align: center;
      align-items: space-between;
   
    }
    
    .card-description {
      font-family: PRIMARY;
      /* font-size: 3vw; */
      font-size:10px; 
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  
    .svg-container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40%;
      width:  100%;
    }

    .svg-container svg{
      width: 90%;
      height: 90%;
    }

    
    .desc-container{
      padding-top: 1vh;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60%;
      width: 100%;
    }
 
  }
  
  @media only screen and (max-width: 766.5px) and (orientation: portrait) {

    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(13, 95, 103, 1);
      padding-top: 15px;
      border-radius: 5px;
      width: 281px;
      height: 159px;


    }
    
    .card-content {
      width: 90%;
      height: 100%;
      text-align: center;
      align-items: space-between;
   
    }
    
    .card-description {
      font-family: PRIMARY;
      /* font-size: 3vw; */
      font-size:clamp(12px, 2.3vw, 40px); ;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  
    .svg-container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40%;
      width:  100%;
    }

    .svg-container svg{
      width: 90%;
      height: 90%;
    }
    
    .desc-container{
      padding-top: 1vh;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60%;
      width: 100%;
    }
  
  }
  
  @media only screen and (min-width: 767px) and (max-width: 1023.5px) {

    
    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      background-color: rgba(13, 95, 103, 1);
      /* background-color: red; */
      border-radius: 5px;
      width: 229px;
      height: 130px;
        }
    
    .card-content {
      width: 100%;
      height: 100%;
      text-align: center;
      flex-direction: column;
      /* background-color: blue; */
      display: flex;
      align-items:center;
      justify-content: center;
   
    }
    
    .card-description {
      font-family: PRIMARY;
      /* font-size: 1.2vw; */
      /* font-size: clamp(12px, 1.2vw, 40px);  */
      font-size: 0.6em;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      width: 98%;
      /* background-color: red; */
    }
  
    .svg-container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40%;
      width:  100%;
      /* background-color: yellow; */
    }

    .svg-container svg {
     height: 100%;
     width: 100%;
    }
    
    .desc-container{
      /* padding-top: 1vh; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
      width: 100%;
    }
   
  }


  
  
  @media only screen and (min-width: 1024px) and (max-width: 1439.5px) {
    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      background-color: rgba(13, 95, 103, 1);
      /* background-color: red; */
      border-radius: 5px;
      width: 323px;
      height: 209px;
        }
    
    .card-content {
      width: 100%;
      height: 100%;
      text-align: center;
      flex-direction: column;
      /* background-color: blue; */
      display: flex;
      align-items:center;
      justify-content: center;
   
    }
    
    .card-description {
      font-family: PRIMARY;
      /* font-size: 1.2vw; */
      /* font-size: clamp(12px, 1.2vw, 40px);  */
      font-size: 0.9em;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      width: 98%;
      /* background-color: red; */
    }
  
    .svg-container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40%;
      width:  100%;
      /* background-color: yellow; */
    }

    .svg-container svg {
     height: 100%;
     width: 100%;
    }
    
    .desc-container{
      /* padding-top: 1vh; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 1440px) {
    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      background-color: rgba(13, 95, 103, 1);
      /* background-color: red; */
      border-radius: 5px;
      width: 323px;
      height: 209px;
        }
    
    .card-content {
      width: 100%;
      height: 100%;
      text-align: center;
      flex-direction: column;
      /* background-color: blue; */
      display: flex;
      align-items:center;
      justify-content: center;
   
    }
    
    .card-description {
      font-family: PRIMARY;
      /* font-size: 1.2vw; */
      /* font-size: clamp(12px, 1.2vw, 40px);  */
      font-size: 1em;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      width: 98%;
      /* background-color: red; */
    }
  
    .svg-container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40%;
      width:  100%;
      /* background-color: yellow; */
    }

    .svg-container svg {
     height: 100%;
     width: 100%;
    }
    
    .desc-container{
      /* padding-top: 1vh; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
      width: 100%;
    }
  }
  